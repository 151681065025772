import { useEffect } from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useTranslation } from "react-i18next";

function Header() {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const location = useLocation();

    // Set French as default language if auth type is basic
    useEffect(() => {
        if (process.env.REACT_APP_AUTH_TYPE === "basic" && i18n.language !== "fr") {
            i18n.changeLanguage("fr");
        }
    }, [i18n]);

    return (
        <>
            {location.pathname !== "/apps/" && location.pathname !== "/apps" && (
                <Box sx={{ p: 3, float: "left" }}>
                    <Button startIcon={<NavigateBeforeIcon />} onClick={() => history.push("/apps")}>
                        {t("back")}
                    </Button>
                </Box>
            )}
            <Box sx={{ p: 3, float: "right" }}>
                <Select variant="standard" value={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)}>
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="fr">Français</MenuItem>
                </Select>
            </Box>
        </>
    );
}

export default Header;
