import React, { useState } from 'react';
import { 
  Button,
  Box,
  Typography,
  Paper
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TestService from "./TestService";
import moment from "moment";

function TestUploadFile() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const docKey = moment().unix() + "_" + file.name;
    TestService.uploadDoc(docKey, file);
  };

  const handleReset = () => {
    setSelectedFile(null);
  };

  return (
    <Box sx={{ p: 3, mt: 10 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          Select File
          <input
            type="file"
            hidden
            onChange={handleFileSelect}
          />
        </Button>
        {selectedFile && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleReset}
          >
            Reset
          </Button>
        )}
      </Box>

      {selectedFile && (
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Selected File Details
          </Typography>
          <Typography>Name: {selectedFile.name}</Typography>
          <Typography>Size: {(selectedFile.size / 1024).toFixed(2)} KB</Typography>
          <Typography>Type: {selectedFile.type}</Typography>
        </Paper>
      )}
    </Box>
  );
}

export default TestUploadFile;
