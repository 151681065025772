import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import AttributeComboBox from "./AttributeComboBox";
import CommSettingsView from "./CommSettingsView";
import StartEndDate from "./StartEndDate";
import Columns from "./Columns";
import MeasurementService from "./MeasurementService";
import dayjs from "dayjs";
import { frFR } from "@mui/x-data-grid/locales";
import {
    DataGridPro,
    LicenseInfo,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function MeasuresMain({ selectedThingId }) {
    const [paging, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [sort, setSort] = useState([{ field: "timestamp", sort: "desc" }]);
    const [filterModel, setFilterModel] = useState({});

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const { t, i18n } = useTranslation();

    const [selectedAttributeId, setSelectedAttributeId] = useState("all");

    const currentDate = dayjs();
    const oneMonthBefore = currentDate.subtract(1, "month");

    const [start, setStart] = useState(oneMonthBefore);
    const [end, setEnd] = useState(currentDate);

    const columns = Columns.getColumns(t);

    useEffect(() => {
        // console.log("useEffect in MeasuresGrid");
        // console.log("start:" + start);
        // console.log("end:" + end);

        MeasurementService.requestMeasurements(
            paging.page,
            paging.pageSize,
            setRows,
            selectedThingId,
            sort,
            selectedAttributeId,
            setRowCount,
            start.unix(),
            end.unix()
        );
    }, [paging.page, paging.pageSize, sort, selectedAttributeId, selectedThingId, filterModel, start, end]);

    const handleSelectedAttributeId = (id) => {
        // console.log(id);
        setSelectedAttributeId(id);
    };

    const setAllAttributes = (attributes) => {
        // console.log(attributes);
        setSelectedAttributeId("all");
    };

    const handleCellClick = (params) => {
        if (params.field == "__check__") {
            return;
        }
    };

    const handleDownload = () => {
        // console.log("handleDownload");
        MeasurementService.downloadMeasurements(selectedThingId, start.unix(), end.unix(), sort, selectedAttributeId, i18n.language)
            .then((response) => {
                // console.log("response", response);
                // Add BOM for Excel compatibility
                const BOM = "\uFEFF";
                const blobWithBOM = new Blob([BOM + response], { type: "text/csv;charset=utf-8" });

                // Create download link
                const url = window.URL.createObjectURL(blobWithBOM);
                const link = document.createElement("a");
                link.href = url;
                link.download = `measures.csv`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((e) => console.log(e));
    };

    function CustomToolbar(props) {
        const { t } = useTranslation();

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <GridToolbarFilterButton />
                <Button color="primary" startIcon={<FileDownloadIcon />} onClick={handleDownload}>
                    {t("btn.export")}
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{ mt: 3 }}>
            <Grid container spacing={0}>
                <Grid item xs={12} sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <CommSettingsView selectedThingId={selectedThingId} />
                </Grid>

                <Grid item xs={12} sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <StartEndDate
                        initStart={start}
                        initEnd={end}
                        callback={(s, e) => {
                            setStart(s);
                            setEnd(e);
                        }}
                    />
                </Grid>

                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                    <AttributeComboBox
                        selectedThingId={selectedThingId}
                        open={true}
                        callbackSelectedAttributeId={handleSelectedAttributeId}
                        callbackAllAttributes={setAllAttributes}
                    ></AttributeComboBox>
                </Grid>

                <Grid item xs={12}>
                    <DataGridPro
                        columns={columns}
                        rows={rows}
                        rowCount={rowCount}
                        // disableColumnFilter
                        pagination={true}
                        paginationMode="server"
                        sortingMode="server"
                        sortModel={sort}
                        paginationModel={paging}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[20, 50]}
                        onSortModelChange={setSort}
                        slots={{
                            toolbar: CustomToolbar,
                            // loadingOverlay: LinearProgress,
                        }}
                        checkboxSelection={false}
                        // onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                        density="compact"
                        onCellClick={handleCellClick}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        disableColumnSelector={false}
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                        onFilterModelChange={setFilterModel}
                        style={{ height: "calc(100vh - 390px)" }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
