import ApiService from "Services/apiService";

const VisibilityService = {
    requestThing: async (page, pageSize, sort, setRows, setRowCount) => {
        // console.log("requestThing...");
        // console.log(thingId);

        let requestOptions = {
            url: `/v2/things`,
            params: {
                page,
                pageSize,
                keyword: "",
                sort,
            },
        };

        return ApiService.getData(requestOptions).then((r) => {
            if (r.code && r.code.includes("no data")) {
                setRows([]);
                setRowCount(0);
                return;
            }

            const mapped = r.content.map((c) => {
                const visibilityField = c.customFields.find((cf) => cf.name === "SUPERVIOT_ICON_VISIBILITY");
                const visible = visibilityField === undefined ? true : visibilityField.value === "true";

                return {
                    id: c.id,
                    name: c.name,
                    visible,
                };
            });

            // console.log(mapped)

            setRows(mapped);
            setRowCount(r.totalElements);
        });
    },

    updateVisibility: async (thingId, visible) => {
        const option = {
            url: `/v2/things/${thingId}/custom_fields`,
            data: [
                {
                    name: "SUPERVIOT_ICON_VISIBILITY",
                    label: "SUPERVIOT_ICON_VISIBILITY",
                    value: visible.toString(),
                    type: "TEXT",
                },
            ],
        };

        // console.log(option);
        return ApiService.putData(option).then((r) => {
            // console.log(r);
            return r;
        });
    },
};

export default VisibilityService;
