import { useEffect, useContext, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import style from "./style.module.css";
import IconButton from "@mui/material/IconButton";
import MainMenu from "./MainMenu";
import Setting from "./Setting";
import UserMenu from "./UserMenu";
import AppContext from "../AppContext";
import UserService from "Services/userService";
import NotificationSetting from "Routes/smartAgriculture/notification/NotificationSetting";
import { AppId } from "../../components/applications/ApplicationData";
import TimeoutSetup from "./TimeoutSetup";
import Help from "./Help";
const baseTitle = "Sensor Apps";

function Header() {
    const history = useHistory();
    const location = useLocation();
    const ctx = useContext(AppContext);
    const [enableGoHome, setEnableGoHome] = useState(true);
    const [isPth, setIsPth] = useState(false);

    const dynamicTitle = useMemo(() => ctx.title || baseTitle, [ctx.title]);

    const homePaths = useMemo(() => [
        "",
        "/smart_agriculture",
        "/sens_dc",
        "/agriculture_water",
        "/device_explorer/device_explorer",
        "/device_explorer/measurement/favorite",
        "/device_explorer/gateways/gatewaylist",
        "/device_explorer/users/grouplist",
        "/device_explorer/users/userlist",
        "/robot",
    ].map(path => `${process.env.PUBLIC_URL}${path}`), []);

    const isOnHome = useMemo(() => 
        homePaths.some(homePath => location.pathname.includes(homePath)),
    [location.pathname, homePaths]);

    const handleClick = () => {
        history.push(`${process.env.PUBLIC_URL}/`);
    };

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            if (user.authorities === "APPLICATION_DEVICE_EXPLORER") {
                setEnableGoHome(false);
            }
            if (user.company === "PTH") {
                setIsPth(true);
            }
        });
    }, []);

    return (
        <header className={style.header}>
            <MainMenu />
            {!isOnHome && (
                <IconButton
                    aria-label="go-back"
                    onClick={() => {
                        if (enableGoHome || location.pathname.split("/").length > 4) {
                            history.goBack();
                        } else {
                            handleClick();
                        }
                    }}
                    size="large"
                >
                    <i className="fa fa-arrow-left" style={{ color: "white" }}></i>
                </IconButton>
            )}
            <h1 style={{ margin: "0px", paddingLeft: "10px" }}>{dynamicTitle}</h1>
            <nav>
                {dynamicTitle !== baseTitle && enableGoHome && (
                    <IconButton aria-label="delete" onClick={handleClick} size="large">
                        <i className="fa fa-th" style={{ color: "white" }}></i>
                    </IconButton>
                )}
                {location.pathname.includes("smart_agriculture") && isPth && (
                    <NotificationSetting appId={AppId.SMART_AGRICULTURE} />
                )}
                {location.pathname.includes("sens_dc") && (
                    <NotificationSetting appId={AppId.SENS_DC} />
                )}
                {location.pathname.includes("temperature_monitoring") && (
                    <NotificationSetting appId={AppId.TEMPERATURE_MONITORING} />
                )}
                {location.pathname.includes("robot") && (
                    <NotificationSetting appId={AppId.ROBOT} />
                )}
                {location.pathname.includes("emera") && (
                    <NotificationSetting appId={AppId.EMERA_V2} />
                )}
                {/* {location.pathname.includes("superviot") && (
                    <NotificationSetting appId={AppId.SUPERVIOT} />
                )} */}
                {location.pathname.includes("device_explorer") && <TimeoutSetup />}
                {location.pathname.includes("device_explorer") && <Help /> }
                <Setting />
                <UserMenu />
            </nav>
        </header>
    );
}

export default Header;
