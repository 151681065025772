import ApiService from "Services/apiService";

const ASSET_ID = "ad881c54-5535-4495-8713-34aaf4387d3f";
const PRODUCT_AGRICULTURE_SENSOR = "a005fcde-d6b8-4068-9bc7-c82f619ed847";
const PRODUCT_AGRICULTURE_V2_CLOVER_SENSOR_ = "8b2c5b76-3dc7-44fc-81c9-8b0d5163437a";
const PRODUCT_SENSOR_HUB = "bedaded0-c917-4172-9046-4a1386bbcac1";
const PRODUCT_RAK200 = "278e5542-9543-4f6a-95ff-7e93495b496b";
const PRODUCT_ENLINK_ZONE = "22f9f62e-4448-11ea-b77f-2e728ce88125"; // co2
const PRODUCT_EM500_SMTC = "8885a412-16df-42ab-a3db-c31929590a2b"; // ec
const PRODUCT_SINGLE_ZET_WATER = "b019865b-dd1c-4c75-94d6-e330ccff8ba3"; // water

const ThingAttributeService = {
    requestThingAttribute: async (wid, setRows, page, pageSize, setRowCount, sort) => {
        let attributeName = "";

        switch (wid) {
            case "WID_AIR_TEMP":
                attributeName = "air_temperature";
                break;
            case "WID_HUMIDITY":
                attributeName = "air_humidity";
                break;
            case "WID_CO2":
                attributeName = "air_co2";
                break;
            case "WID_EC":
                attributeName = "soil_ec";
                break;
            case "WID_SOIL_TEMP":
                attributeName = "soil_temperature";
                break;
            case "WID_WATER":
                attributeName = "soil_moisture";
                break;
            case "WID_LIGHT":
                attributeName = "solar_radiation";
                break;
            default:
                break;
        }

        let params = {
            page: page,
            pageSize: pageSize,
            productId: [
                PRODUCT_AGRICULTURE_SENSOR,
                PRODUCT_AGRICULTURE_V2_CLOVER_SENSOR_,
                PRODUCT_SENSOR_HUB,
                PRODUCT_RAK200,
                PRODUCT_ENLINK_ZONE,
                PRODUCT_EM500_SMTC,
                PRODUCT_SINGLE_ZET_WATER,
            ],
            attributeName: attributeName,
            sort: sort.map((s) => s.field + ":" + s.sort).join(","),
        };

        // console.log("/////-------");
        // console.log(params);

        return ApiService.getDataWithParams("/v2/things/attributes", params).then((response) => {
            // console.log("/////-------");
            // console.log(response);

            let temp = [];

            response.content.map((t) => {
                // console.log(t);

                const attributes = t.product.model.attribute;
                const mappedAttribute = attributes.map((a) => {
                    let entity = {
                        id: t.id + "," + a.id,
                        thingId: t.id,
                        fixedName: t.fixedName,
                        company: t.company,
                        status: t.status,
                        // "attributes.id": a.id,
                        "attributes.name": a.name,
                    };

                    return entity;
                });

                // console.log("//--//--")
                // console.log(mappedAttribute);
                mappedAttribute.forEach((m) => {
                    // console.log(m);
                    temp.push(m);
                });
            });

            setRows(temp);
            setRowCount(response.totalElements);
        });
    },

    associateAttribute: async (thingId, attributeId, wid) => {
        let requestOptions = {
            url: `/v2/asset/associate`,
            data: {
                assetId: ASSET_ID,
                thingId: thingId,
                attributeId: attributeId,
                attributeName: wid,
            },
        };

        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastMeasure: async (wid, setThing, setAttribute, setLastMeasure, setCountAlerts) => {
        let requestOptions = {
            url: `/v2/asset/${ASSET_ID}/${wid}`,
        };

        // console.log("requestOptions....")
        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                if (response.thing) {
                    setThing(response.thing);
                }
                if (response.attribute) {
                    setAttribute(response.attribute);
                }
                if (response.lastMeasure) {
                    setLastMeasure(response.lastMeasure);
                }
                if (response.lastMeasure) {
                    setCountAlerts(response.countAlerts);
                }
                return response;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestMappedAttribute: async (wid, setSelectedRow) => {
        let requestOptions = {
            url: `/v2/asset/mapped/${ASSET_ID}/${wid}`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            if (!r.code && r.thing && r.attribute) {
                setSelectedRow(r.thing.id + "," + r.attribute.id);
            }
        });
    },
};

export default ThingAttributeService;
