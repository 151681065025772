import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MeasurementService from "./MeasurementService";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function CommSettingsView({ selectedThingId }) {
    const { t, i18n } = useTranslation();
    const [number, setNumber] = useState("");
    const [transmission, setTransmission] = useState("");
    const [measure, setMeasure] = useState("");

    useEffect(() => {
        // console.log("uef in CommSettingsView");

        MeasurementService.requestSetting(selectedThingId, setNumber, setTransmission, setMeasure);
    }, [selectedThingId]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={6} sx={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: 1, pr: 1 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t("label.transmission")} {transmission}
                    </Typography>
                    {/* <TextField
                        id="filled-read-only-input"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                        sx={{ width: 100 }}
                        value={transmission}
                    /> */}
                    
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: 1 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t("label.measure")} {measure}
                    </Typography>
                    {/* <TextField
                        id="filled-read-only-input"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                        sx={{ width: 100 }}
                        value={measure}
                    /> */}
                </Grid>
            </Grid>
        </Box>
    );
}
