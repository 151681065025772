import React, { useState, useEffect } from "react";
import { Stage, Layer, Image as KonvaImage } from "react-konva";
import Box from "@mui/material/Box";
import useImage from "use-image";
import IslandKonvaImage from "./IslandKonvaImage";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import ImageService from "Services/imageService";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ImgCompost from "Assets/images/riot_bg.jpeg";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CustomStyledButton from "../../../components/ui/CustomStyledButton";
import CreateIcon from "@mui/icons-material/Create";

export default function IslandMapV2({ positions, callback }) {
    const { t, i18n } = useTranslation();
    const [imgCompost] = useImage(ImgCompost);
    const [width, setWidth] = useState(1920);
    const [height, setHeight] = useState(720);
    const [reload, setReload] = useState();

    const code = "RIOT_BG_IMAGE";
    const [backgroundImage, setBackgroundImage] = useState();
    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (!backgroundImage) {
            ImageService.requestBackgroundImage(code).then((r) => {
                if (r) {
                    const bgImg = new window.Image();
                    bgImg.src = "data:image/png;base64," + r;
                    setBackgroundImage(bgImg);

                    bgImg.onload = () => {
                        setWidth(bgImg.naturalWidth);
                        setHeight(bgImg.naturalHeight);
                    };
                }
            });
        }
    }, [positions, backgroundImage, code]);

    const handleImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const imgElement = new Image(); // Create a new image element
            imgElement.onload = () => {
                // Check if the image needs resizing
                const maxWidth = 1920;
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                let newWidth = imgElement.width;
                let newHeight = imgElement.height;

                if (imgElement.width > maxWidth) {
                    newWidth = maxWidth;
                    newHeight = (imgElement.height * maxWidth) / imgElement.width;
                }

                // console.log("Original dimensions:", imgElement.width, imgElement.height);
                // console.log("New dimensions:", newWidth, newHeight);

                // Set canvas dimensions
                canvas.width = newWidth;
                canvas.height = newHeight;

                // Draw resized image on canvas
                ctx.drawImage(imgElement, 0, 0, newWidth, newHeight);

                // Convert canvas to Blob
                canvas.toBlob((blob) => {
                    if (blob) {
                        // console.log("Blob created successfully");
                        // Upload resized image
                        ImageService.uploadBackgroundImage(code, blob)
                            .then((r) => {
                                setBackgroundImage(null);
                                setReload(moment());
                            })
                            .catch((error) => {
                                console.error("Error uploading image:", error);
                            });
                    } else {
                        console.error("Failed to create Blob");
                    }
                }, file.type);
            };

            imgElement.onerror = (error) => {
                console.error("Image loading error:", error);
            };

            imgElement.src = event.target.result;
        };

        reader.onerror = (error) => {
            console.error("File reading error:", error);
        };

        reader.readAsDataURL(file);
    };

    const handleCallback = (name) => {
        callback(name);
    };

    const zoomIn = () => {
        setScale((prevScale) => Math.min(prevScale * 1.2, 5));
    };

    const zoomOut = () => {
        setScale((prevScale) => Math.max(prevScale / 1.2, 0.2));
    };

    const getImagePosition = () => {
        if (backgroundImage) {
            const imageWidth = backgroundImage.width * scale;
            const stageWidth = width;
            const x = (stageWidth - imageWidth) / 2;
            return x;
        }
        return 0;
    };

    return (
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2} sx={{ alignItems: "center", wrap: "nowrap" }}>
                <Grid item xs={12} sx={{ gap: 1, display: "flex", flexDirection: "row" }}>
                    <CustomStyledButton icon={<ZoomInIcon />} callback={zoomIn} />
                    <CustomStyledButton icon={<ZoomOutIcon />} callback={zoomOut} />

                    <label htmlFor="contained-button-file">
                        <input style={{ display: "none" }} accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />
                        <CustomStyledButton component="span" icon={<AddPhotoAlternateIcon />} />
                    </label>

                    {/* <CustomStyledButton icon={<CreateIcon />} callback={() => console.log("Open popup...")} /> */}
                </Grid>
                <Grid item xs={12} sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100%" }}>
                    <Stage width={width} height={height}>
                        <Layer>
                            {backgroundImage && (
                                <KonvaImage
                                    image={backgroundImage}
                                    scaleX={scale}
                                    scaleY={scale}
                                    x={getImagePosition()} // Center horizontally
                                />
                            )}
                        </Layer>
                        <Layer>
                            {positions &&
                                positions.map((p) => {
                                    return <IslandKonvaImage positions={p} key={uuidv4()} callback={(name) => handleCallback(name)} />;
                                })}
                        </Layer>
                    </Stage>
                </Grid>
            </Grid>
        </Box>
    );
}
