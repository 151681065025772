import React, { useState, Suspense, useEffect } from "react";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Header from "./Header";
import style from "./style.module.css";
import { Auth } from "aws-amplify";
import validator from "validator";
import Button from "@mui/material/Button";
import basicAuthService from "Services/basicAuthService";

function LoginPage() {
    let history = useHistory();
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // first login
    const [firstLoginUser, setFirstLoginUser] = useState();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [info, setInfo] = useState([]);

    const authType = process.env.REACT_APP_AUTH_TYPE;

    useEffect(() => {
        // Language is French by default for On-Premise version
        if (process.env.REACT_APP_AUTH_TYPE === "basic") {
            i18n.changeLanguage("fr");
        }
    }, [i18n, error]);

    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (authType === "basic") {
            basicAuthService
                .login(email, password)
                .then((user) => {
                    setLoading(false);
                    history.push("/apps");
                })
                .catch((e) => {
                    setLoading(false);
                    setError("Incorrect username or password");
                });
        } else {
            Auth.signIn({ username: email, password: password })
                .then((user) => {
                    if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
                        setLoading(false);
                        setFirstLoginUser(user);
                    } else {
                        setLoading(false);
                        history.push("/apps");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    setError("Incorrect username or password");
                });
        }
    };

    const handleForgot = () => {
        if (validator.isEmail(email)) {
            if (authType === "basic") {
                basicAuthService.forgotPassword(email).then((u) => {
                    history.push(`/forgot/${encodeURIComponent(email)}`);
                });
            } else {
                Auth.forgotPassword(email, {})
                    .then((u) => {
                        history.push(`/forgot/${encodeURIComponent(email)}`);
                    })
                    .catch((e) => {
                        console.error(e);
                        setError(t("login.user-notfound"));
                    });
            }
        } else {
            setError(t("login.invalid-email"));
            return;
        }
    };

    const handleReset = () => {
        setInfo([]);

        if (validator.isEmpty(newPassword)) {
            setError("login.password-cannottbe-empty");
            return;
        } else if (newPassword != confirmPassword) {
            setError(t("login.passward-not-same"));
            return;
        }

        Auth.completeNewPassword(firstLoginUser, newPassword)
            .then((u) => {
                // sucesss
                setLoading(false);
                setFirstLoginUser(undefined);
            })
            .catch((e) => {
                // console.log(e);
                // console.log(t("login.pssword-policy-1"));

                setInfo([
                    t("login.pssword-policy"),
                    t("login.pssword-policy-1"),
                    t("login.pssword-policy-2"),
                    t("login.pssword-policy-3"),
                    t("login.pssword-policy-4"),
                    t("login.pssword-policy-5"),
                ]);
            });
    };

    return (
        <>
            <div className={style.background}>
                <Box sx={{ backgroundColor: "white" }}>
                    <Suspense fallback={<span />}>
                        <Header />
                    </Suspense>

                    {!firstLoginUser && (
                        <Box sx={{ p: 10 }}>
                            {/* Superviot Logo for On Premise version*/}
                            {process.env.REACT_APP_AUTH_TYPE === "basic" ? (
                                <>
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                                            {t("superviot.app_name")}
                                        </Typography>
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <img src="/apps/logo_tahiti.jpg" alt="Pilot Things" style={{ marginBottom: 20, height: 100 }} />
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <img src="/apps/full-logo.png" alt="Pilot Things" style={{ marginBottom: 20 }} />
                            )}

                            {/* <h1>{t('login.connect')}</h1> */}
                            <Stack component="form" spacing={2} onSubmit={submit}>
                                <TextField
                                    variant="standard"
                                    label={t("login.email")}
                                    type="email"
                                    fullWidth
                                    required
                                    disabled={loading}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    variant="standard"
                                    label={t("login.password")}
                                    type="password"
                                    fullWidth
                                    required
                                    value={password}
                                    disabled={loading}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {/* opacity is a clever trick to hide the error message while still reserving space for it. */}
                                <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                                    {error}
                                </Alert>
                                <LoadingButton loading={loading} loadingPosition="end" variant="contained" type="submit" endIcon={<LockOpenIcon />} fullWidth>
                                    {t("login.login")}
                                </LoadingButton>
                            </Stack>
                            <Box sx={{ mt: 3 }} textAlign="center">
                                <Typography variant="body1" onClick={() => handleForgot()} sx={{ cursor: "pointer" }}>
                                    {t("login.forgot-password")}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {firstLoginUser && (
                        <Box sx={{ p: 10 }}>
                            <img src="/apps/full-logo.png" alt="Pilot Things" style={{ marginBottom: 20 }} />

                            <h4>{t("login.change-password")}</h4>
                            <Stack component="form" spacing={2} onSubmit={submit}>
                                <TextField
                                    variant="standard"
                                    label={t("login.password")}
                                    type="password"
                                    fullWidth
                                    required
                                    value={newPassword}
                                    disabled={loading}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <TextField
                                    variant="standard"
                                    label={t("login.enter-password-again")}
                                    type="password"
                                    fullWidth
                                    required
                                    value={confirmPassword}
                                    disabled={loading}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />

                                {/* opacity is a clever trick to hide the error message while still reserving space for it. */}
                                {error && (
                                    <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                                        {error}
                                    </Alert>
                                )}
                                {/* <LoadingButton loading={loading} loadingPosition="end" variant="contained" type="submit" endIcon={<LockOpenIcon />} fullWidth>{t('login.login')}</LoadingButton> */}

                                <Box sx={{ flexGrow: 1 }}>
                                    {info &&
                                        info.map((i, index) => {
                                            // console.log(i);

                                            if (index === 0) {
                                                return (
                                                    <Alert key={index} severity="info" sx={{ opacity: 1 }}>
                                                        {i}
                                                    </Alert>
                                                );
                                            } else {
                                                return (
                                                    <Typography key={index} variant="body2">
                                                        {i}
                                                    </Typography>
                                                );
                                            }
                                        })}
                                </Box>

                                <Button variant="contained" onClick={handleReset}>
                                    {t("login.send")}
                                </Button>
                            </Stack>
                        </Box>
                    )}
                </Box>
            </div>
        </>
    );
}

export default LoginPage;
