import React, { useState } from "react";
import ElectricityChart from "./ElectricityChart";
import Box from "@mui/material/Box";
import SalleElectricity1 from "./SalleElectricity1";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from "dayjs";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function ElectricityPanel(params) {
    const [value, setValue] = useState(dayjs());

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleRoom = (name) => {
        // console.log(name);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label="Date"
                    inputFormat="MM/dd/yyyy"
                    // inputFormat="MM/yyyy"
                    // views={['year', 'month']}
                    // openTo="month"
                    value={value}
                    onChange={handleChange}
                    slotProps={{ textField: { variant: "outlined" } }}
                />
            </LocalizationProvider>

            <ElectricityChart siteName="Siege" subtitle="Electricity" chartData={SalleElectricity1.data} callback={handleRoom} />
        </Box>
    );
}
