import ApiService from "Services/apiService";

const FlowService = {
    requestSite: async (siteId) => {
        let requestOptions = {
            url: `/api/sites/${siteId}`,
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);
            return response;
        });
    }, 

    requestThing: async (siteId) => {
        let requestOptions = {
            url: `/api/things?filter=site.id:eq_${siteId}&detailed=true`,
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions).then((response) => {
            // console.log(response);
            return response;
        });
    },

    saveIconPosition: async (thingId, x, y) => {
        let requestOptions = {
            url: `/v2/things/${thingId}/custom_fields`,
            data: [
                {
                    name: "EMERA_V2_ICON_POSITION",
                    label: "EMERA_V2_ICON_POSITION",
                    value: JSON.stringify({
                        x,
                        y,
                    }),
                    type: "TEXT",
                },
            ],
        };
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default FlowService;
