import { useEffect, useState } from "react";
import _ from "underscore";
import Tooltip from "@mui/material/Tooltip";

export default function BatteryIcon({ thing }) {
    const [batteryLevel, setBatteryLevel] = useState();
    const [iconClass, setIconClass] = useState();

    useEffect(() => {
        // console.log(thing);

        if (!thing) {
            return;
        }
        const measurements = thing.lastMeasurements?.measurements;
        if (!measurements) {
            return;
        }

        let batteryLevel = measurements._Battery ?? measurements.battery ?? measurements._battery;
        if (batteryLevel === undefined) {
            return;
        }

        setBatteryLevel(batteryLevel);

        let iconClass;
        if (batteryLevel < 5) {
            iconClass = "fa fa-battery-empty";
        } else if (batteryLevel < 30) {
            iconClass = "fa fa-battery-quarter";
        } else if (batteryLevel < 60) {
            iconClass = "fa fa-battery-half";
        } else if (batteryLevel < 90) {
            iconClass = "fa fa-battery-three-quarters";
        } else {
            iconClass = "fa fa-battery-full";
        }

        setIconClass(iconClass);
    }, [thing]);

    return (
        <Tooltip title={`${batteryLevel}%`}>
            <i className={iconClass} style={{ paddingTop: "4px" }}></i>
        </Tooltip>
    );
}
