import React, { useState, useEffect } from "react";
import ElectricityChart from "../analysis/ElectricityChart";
import Box from "@mui/material/Box";
import SalleElectricity1 from "../analysis/SalleElectricity1";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LicenseInfo } from "@mui/x-license-pro";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function ChartDetail({ entity }) {
    const [value, setValue] = useState(dayjs());

    useEffect(() => {
        // console.log("uef in ChartDetail");
        // console.log(entity);
    }, []);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleRoom = (name) => {
        // console.log(name);
    };

    return (
        <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Stack spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Date"
                        inputFormat="MM/dd/yyyy"
                        // inputFormat="MM/yyyy"
                        // views={['year', 'month']}
                        // openTo="month"
                        value={value}
                        onChange={handleChange}
                        // renderInput={(params) => <TextField {...params} sx={{ width: 200 }} />}
                        slotProps={{ textField: { variant: "outlined", size: "small" } }}
                    />
                </LocalizationProvider>

                <ElectricityChart siteName="" subtitle="" chartData={SalleElectricity1.data} callback={handleRoom} height={300} />
            </Stack>
        </Box>
    );
}
