import Cookies from "js-cookie";
import ApiService from "Services/apiService";
import UserService from "Services/userService";
// Add custom event for auth state changes
const AUTH_STATE_CHANGE = "basicAuthStateChange";

const login = async (email, password) => {
    let token = await ApiService.getCsrf();

    try {
        var requestOptions = {
            method: "POST",
            mode: "cors",
            headers: ApiService.init.headers,
        };

        requestOptions.headers.set("Content-Type", "application/json");
        requestOptions.headers.set("X-CSRF-TOKEN", token);
        requestOptions.headers.set("Authorization", "Basic " + Buffer.from(email + ":" + password).toString("base64"));

        const result = await fetch("/login", requestOptions);

        if (result.status !== 200) {
            throw new Error("Invalid credentials");
        }

        const resultUser = await fetch("/api/users/login", requestOptions);
        const { user, jwtToken } = await resultUser.json();
        // console.log("check it out", user, jwtToken);

        // Store the JWT token in a secure cookie
        Cookies.set("jwtToken", jwtToken, { secure: true, sameSite: "strict" });

        const arr_auth = user.authorities.split(",").map((a) => a.trim());
        user["groups"] = arr_auth.filter((a) => !a.startsWith("APPLICATION_"));
        user["application"] = arr_auth.filter((a) => a.startsWith("APPLICATION_"));

        user["zoneinfo"] = user.timezone;
        delete user.timezone;

        if (user.groups.includes("SUPER_ADMIN")) {
            user.authorities = "SUPER_ADMIN";
        } else if (user.groups.includes("CLIENT_ADMIN")) {
            user.authorities = "CLIENT_ADMIN";
        } else {
            user.authorities = "USER";
        }

        // user["company"] = user.company.name;
        // console.log("login", user);

        const companyInfo = await UserService.requestCompanyByName(user.company);

        if (companyInfo) {
            user["isGrandChildrenCompany"] = companyInfo.isGrandChildrenCompany;
            user["isRootCompany"] = companyInfo.isRootCompany;
            user["companyId"] = companyInfo.companyId;
        }

        const groups = user.groups;
        for (let i = 0; i < groups.length; i++) {
            if (groups[i] == "CLIENT_DASHBOARD") {
                user["client_dashboard"] = true;
            }
        }

        // console.log("In authService...");
        // console.log("login user", user);
        Cookies.set("user", JSON.stringify(user));

        window.dispatchEvent(
            new CustomEvent(AUTH_STATE_CHANGE, {
                detail: {
                    event: "signIn",
                    data: user,
                },
            })
        );

        return user;
    } catch (error) {
        // console.error("Login failed:", error);
        throw error;
    }
};

const logout = async () => {
    Cookies.remove("user");
    Cookies.remove("jwtToken"); // Remove the JWT token on logout

    // let token = await ApiService.getCsrf();
    try {
        var requestOptions = {
            method: "POST",
            headers: ApiService.init.headers,
        };

        requestOptions.headers.set("Content-Type", "application/json");
        // requestOptions.headers.set("X-Csrf-Token", token);
        // console.log(requestOptions);

        const result = await fetch("/api/users/logout", requestOptions);
        // console.log(result);

        if (result.ok) {
            // console.log("Logout successful");
        } else {
            console.error("Logout failed with status:", result.status);
        }
    } catch (error) {
        console.error("Logout failed:", error);
        return false;
    }

    // Dispatch custom event for logout
    window.dispatchEvent(
        new CustomEvent(AUTH_STATE_CHANGE, {
            detail: {
                event: "signOut",
                data: null,
            },
        })
    );
};

const currentAuthenticatedUser = async () => {
    const userCookie = Cookies.get("user");
    // console.log("getUser", userCookie);
    return userCookie ? JSON.parse(userCookie) : null;
};

const isLogged = async () => {
    const userCookie = Cookies.get("user");
    return userCookie ? true : false;
};

const forgotPassword = async (email) => {
    let requestOptions = {
        url: "/api/users/forgot_password",
        data: {
            email: email,
        },
    };

    const result = await ApiService.putData(requestOptions);
    if (result && result.code == "failure") {
        throw result.message;
    }
};

const resetPassword = async (email, verificationCode, newPassword) => {
    let requestOptions = {
        url: "/api/users/reset_password",
        data: {
            email: email,
            verificationCode: verificationCode,
            newPassword: newPassword,
        },
    };

    const result = await ApiService.putData(requestOptions);
    if (result && result.code == "failure") {
        throw result.message;
    }
};

// Add a new function to get the JWT token
const getJwtToken = () => {
    return Cookies.get("jwtToken");
};

// Modify the existing export statement
export { isLogged, login, logout, currentAuthenticatedUser, forgotPassword, resetPassword, getJwtToken };

export default {
    login,
    logout,
    currentAuthenticatedUser,
    forgotPassword,
    resetPassword,
    AUTH_STATE_CHANGE, // Export the event name
    isLogged,
};
