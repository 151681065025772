import React, { useState, useEffect } from "react";
import _ from "underscore";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ThresholdDialogV2Input from "./ThresholdDialogV2Input";
import ThresholdServiceV2 from "./ThresholdServiceV2";
import ThresholdColumns from "./ThresholdColumns";
import Item from "Components/ui/Item";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function ThresholdDialogV2(props) {
    const { t, i18n } = useTranslation();
    const { title, onClose, thingId, attributeId, ...other } = props;

    const [rows, setRows] = useState();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [sortModel, setSortModel] = useState([{ field: "startTime", sort: "desc" }]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isOpenInputDialog, setIsOpenInputDialog] = useState(false);

    const [reload, setReload] = useState();

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose();
    };

    const handleAdd = () => {
        setIsOpenInputDialog(true);
    };

    const handleRemove = () => {
        ThresholdServiceV2.deleteThreshold(selectedIds).then(() => {
            setReload(moment().unix());
        });
    };
    const handleSelectionModel = (selectedIds) => {
        setSelectedIds(selectedIds);
    };

    const handleSortModelChange = (param) => {
        console.error(param);
        setSortModel(param);
    };

    useEffect(() => {
        let qParams = [];

        _.each(sortModel, function (sm) {
            let result = "";

            switch (sm.sort) {
                case "asc":
                    result += "+";
                    break;
                case "desc":
                    result += "-";
                    break;
                default:
                    result += "+";
                    break;
            }

            switch (sm.field) {
                case "startTime":
                    result += "start_time";
                    break;
                case "endTime":
                    result += "end_time";
                    break;
                case "isAllTime":
                    result += "is_all_time";
                    break;
                case "lowValue":
                    result += "low_value";
                    break;
                case "highValue":
                    result += "high_value";
                    break;
                default:
                    break;
            }

            qParams.push(result);
        });

        let sortQuery = "";
        if (qParams.length > 0) {
            sortQuery = "&sort=" + qParams.join(",");
        }

        if (thingId && attributeId) {
            ThresholdServiceV2.requestThreshold(thingId, attributeId, paginationModel.page, paginationModel.pageSize, setRows, setRowCount, sortQuery);
        }
    }, [reload, paginationModel.page, paginationModel.pageSize, thingId, attributeId, sortModel]);

    const threshold_columns = ThresholdColumns.getColumns(t);

    return (
        <>
            <Dialog maxWidth="lg" {...other} disableEnforceFocus={true}>
                <DialogTitle>{t("alert.name")}</DialogTitle>
                <DialogContent>
                    <div style={{ height: 500, width: 930 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Item>
                                    <Stack spacing={2} direction="row-reverse">
                                        <Button variant="contained" style={{ color: "#fff", backgroundColor: "#E44959" }} onClick={() => handleRemove()}>
                                            {t("btn.remove")}
                                        </Button>
                                        <Button variant="contained" style={{ color: "#fff", backgroundColor: "#9CC96B" }} onClick={() => handleAdd()}>
                                            {t("btn.add")}
                                        </Button>
                                    </Stack>
                                </Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Item style={{ width: "100%", height: "400px" }}>
                                    <DataGridPro
                                        rows={rows}
                                        columns={threshold_columns}
                                        paginationModel={paginationModel}
                                        onPaginationModelChange={setPaginationModel}
                                        rowCount={rowCount}
                                        pagination={true}
                                        paginationMode="server"
                                        sortingMode="server"
                                        pageSizeOptions={[10, 50, 100]}
                                        onSortModelChange={handleSortModelChange}
                                        checkboxSelection={true}
                                        onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                                        density="compact"
                                        filterMode="server"
                                        // onCellEditCommit={handleName}
                                        disableMultipleColumnsFiltering={true}
                                        disableColumnReorder={true}
                                        sx={{
                                            "& .MuiTablePagination-selectLabel": {
                                                marginTop: 2,
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                marginTop: 2,
                                            },
                                        }}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    id: false,
                                                },
                                            },
                                        }}
                                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} color="primary">
                        {t("btn.cancel")}
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        {t("btn.ok")}
                    </Button>
                </DialogActions>
            </Dialog>
            <ThresholdDialogV2Input
                open={isOpenInputDialog}
                close={() => {
                    setIsOpenInputDialog(false);
                    setReload(moment().unix());
                }}
                thingId={thingId}
                attributeId={attributeId}
            ></ThresholdDialogV2Input>
        </>
    );
}
