import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import IslandMap from "./island/IslandMap";
import SideManu from "./island/sidebar/SideManu";
import AppContext from "Components/AppContext";
import UserService from "Services/userService";
import MeasurementServiceV2 from "Routes/deviceExplorer/measurements/MeasurementServiceV2";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import basicAuthService from "../../services/basicAuthService";

export default function SuperviotMain() {
    const [positions, setPositions] = useState();
    const [selectedPosition, setSelectedPosition] = useState();
    const [reload, setReload] = useState();
    const ctx = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        ctx.updateTitle("Superviot");
    }, [ctx]);

    useEffect(() => {
        let isMounted = true;

        UserService.getAuthenticatedUser().then((user) => {
            const requestFunction =
                user.authorities === "USER"
                    ? () =>
                          MeasurementServiceV2.requestByUser("", 0, 100, undefined, undefined, [{ field: "name", sort: "DESC" }], user.id).catch((e) => {
                              console.log("Session expired", e);
                              basicAuthService.logout();
                              window.location.href = "/apps";
                          })
                    : () =>
                          MeasurementServiceV2.requestData("", 0, 100, undefined, undefined, [{ field: "name", sort: "DESC" }]).catch((e) => {
                              console.log("Session expired", e);
                              basicAuthService.logout();
                              window.location.href = "/apps";
                          });

            requestFunction().then((r) => {
                if (!r || !isMounted) return;

                const mapped = r
                    .map((item) => {
                        // visibility : default true, if SUPERVIOT_ICON_VISIBILITY is false, don't show the item
                        let visibility = item.customFields?.find((c) => c.name === "SUPERVIOT_ICON_VISIBILITY")?.value;
                        visibility = visibility === undefined ? true : visibility === "false" ? false : Boolean(visibility);

                        return {
                            name: item.name,
                            thingId: item.id,
                            siteName: item["site.name"],
                            visibility,
                        };
                    })
                    .filter((item) => {
                        // if SUPERVIOT_ICON_VISIBILITY is false, don't show the item
                        return item.visibility;
                    });

                setPositions(mapped);
            });
        });

        return () => {
            isMounted = false;
        };
    }, [selectedPosition, reload]);

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2, display: "flex" }}>
            <IslandMap positions={positions} callback={(p) => setSelectedPosition(p)} setReloadParent={() => setReload(dayjs().valueOf())} />
            <SideManu position={selectedPosition} />
        </Box>
    );
}
