import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { CustomButton } from "../../../components/ui/CustomButton";
import DatabaseService from "./DatabaseService";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { DatabaseColumns } from "./DatabaseColumns";
import { frFR } from "@mui/x-data-grid/locales";
import { TextField } from "@mui/material";
import { debounce } from "lodash";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function DatabaseUsage({ companyId }) {
    const { t, i18n } = useTranslation();
    const [rows, setRows] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [usageSize, setUsageSize] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [days, setDays] = useState(0);
    const [daysError, setDaysError] = useState(false);

    useEffect(() => {
        if (companyId) {
            // console.log("companyId", companyId);
            setTotalSize(0);
            setUsageSize(0);
            setRows([]);

            DatabaseService.getCompany(companyId).then((r) => {
                if (r.retentionDays) {
                    setDays(r.retentionDays);
                } else {
                    setDays(0);
                }
            });
        }
    }, [companyId]);

    const handleRequestDatabaseUsage = () => {
        if (companyId) {
            setIsLoading(true);
            setDataLoaded(false);

            DatabaseService.requetDatabseUsage(companyId, setTotalSize, setUsageSize, setRows).then((r) => {
                // console.log(r);
                setIsLoading(false);
                setDataLoaded(true);
            });
        }
    };

    const formatSize = (sizeInBytes) => {
        const sizeInGB = sizeInBytes / (1024 * 1024 * 1024);
        return `${sizeInGB.toFixed(2)} GB (${sizeInBytes.toLocaleString()} bytes)`;
    };
    
    const debounceDaysChange = useCallback(
        debounce((companyId, value) => {
            // console.log(companyId, value);
            DatabaseService.updateRetention(companyId, value);
        }, 500),
        []
    );

    const columns = DatabaseColumns(t);

    if (!companyId) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography variant="body1">A company is not selected</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2, gap: 2, alignItems: "center" }}>
                <Typography gutterBottom>{t("biz.limit_retention")}</Typography>
                <TextField
                    margin="dense"
                    id="days"
                    label=""
                    type="number"
                    value={days}
                    onChange={(e) => {
                        setDays(e.target.value);
                        setDaysError(false);
                        debounceDaysChange(companyId, e.target.value);
                    }}
                    inputProps={{ 
                        min: 0,
                        style: { textAlign: 'right', height: 10 }
                    }}
                    sx={{ width: 100 }}
                    helperText={daysError ? t("biz.error") : ""}
                />
            </Box>
            <Box sx={{ display: "flex", gap: 2, flexDirection: "column", mb: 2 }}>
                {isLoading ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                        <Typography variant="body1">Loading...</Typography>
                    </Box>
                ) : dataLoaded ? (
                    <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                        <Typography variant="body1">Total: {formatSize(totalSize)}</Typography>
                        <Typography variant="body1">Usage: {formatSize(usageSize)}</Typography>
                    </Box>
                ) : (
                    <Typography variant="body1">Click "Calculate" to load data</Typography>
                )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
                <CustomButton name="Calculate" callback={handleRequestDatabaseUsage} />
            </Box>
            <Box>
                <DataGridPro
                    columns={columns}
                    rows={rows}
                    disableColumnFilter
                    style={{ height: "calc(100vh - 220px)" }}
                    checkboxSelection={false}
                    density="compact"
                    disableSelectionOnClick={true}
                    disableRowSelectionOnClick={true}
                    sx={{
                        "& .MuiTablePagination-selectLabel": {
                            marginTop: 2,
                        },
                        "& .MuiTablePagination-displayedRows": {
                            marginTop: 2,
                        },
                    }}
                    columnVisibilityModel={{
                        id: false,
                    }}
                    localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                />
            </Box>
        </Box>
    );
}
