import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TimezonesBox from "./TimezonesBox";
import validator from "validator";
import _ from "underscore";
import CloudUserService from "./CloudUserService";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/ui/BootstrapDialog";
import DialogContent from "@mui/material/DialogContent";
import { CustomButton } from "../../../components/ui/CustomButton";
import CompanyBox from "./CompanyBox";
import Snackbar from "@mui/material/Snackbar";

export default function CreateUserDialog({ open, setOpen, callback, appId }) {
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("ATIM");
    const [role, setRole] = useState("user");
    const [locale, setLocale] = useState("en");
    const [zoneInfo, setZoneInfo] = useState("America/Montreal");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClose = () => {
        setEmail("");
        setFirstName("");
        setLastName("");
        setCompany("");
        setRole("user");
        setLocale("en");
        setZoneInfo("America/Montreal");

        setOpen(false);
        setLoading(false);
        setError(null);
    };

    const handleSubmit = () => {
        // console.log("handleSubmit...")

        if (_.isEmpty(email)) {
            setError("Please enter an email");
            return;
        }
        if (!validator.isEmail(email)) {
            setError("Please enter valid email address");
            return;
        }

        if (_.isEmpty(lastName)) {
            setError("Please enter a last name");
            return;
        }

        setLoading(true);
        setError(null);

        if (process.env.REACT_APP_AUTH_TYPE === "basic") {
            CloudUserService.createUserOnPremise(email, firstName, lastName, company, role, locale, zoneInfo, appId)
                .then((r) => {
                    callback();
                    setLoading(false);

                    if (r && r.code && r.code === "success") {
                        setEmail("");
                        // setFirstName("");
                        // setLastName("");
                        setCompany("");
                        setRole("user");
                        setLocale("en");
                        setZoneInfo("America/Montreal");
                        setError("");

                        setOpenSnackbar(true);
                        setOpen(false);
                    } else if (r && r.code && r.code === "failure") {
                        setError(r.message);
                    }
                })
                .catch((e) => {    
                    callback();
                    setLoading(false);
                    setError(e.message);
                });
        } else {
            CloudUserService.createUser(email, firstName, lastName, company, role, locale, zoneInfo, appId)
                .then((r) => {
                    callback();
                    setLoading(false);

                    if (r && r.code && r.code === "success") {
                        setEmail("");
                        // setFirstName("");
                        // setLastName("");
                        setCompany("");
                        setRole("user");
                        setLocale("en");
                        setZoneInfo("America/Montreal");
                        setError("");

                        setOpenSnackbar(true);
                        setOpen(false);
                    } else if (r && r.code && r.code === "failure") {
                        setError(r.message);
                    }
                })
                .catch((e) => {
                    callback();
                    setLoading(false);
                    setError(e);
                });
        }
    };

    useEffect(() => {
        // console.log("appId", appId);
    }, [appId]);

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t("de.add_user")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ pt: 2, pl: 5, pr: 5, pb: 2 }}>
                        <Stack spacing={2}>
                            <TextField
                                variant="standard"
                                label={t("login.email")}
                                type="email"
                                fullWidth
                                required
                                disabled={loading}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="standard"
                                label={t("login.first-name")}
                                type="text"
                                fullWidth
                                disabled={loading}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <TextField
                                variant="standard"
                                label={t("login.last-name")}
                                type="text"
                                fullWidth
                                required
                                disabled={loading}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />

                            <CompanyBox loading={loading} callback={(c) => setCompany(c)} />

                            <FormControl variant="filled" fullWidth required>
                                <InputLabel id="sel-role-label" sx={{ ml: 0, alignContent: "left" }}>
                                    {"Role"}
                                </InputLabel>
                                <Select
                                    labelId="sel-role-label"
                                    id="sel-role"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    disabled={loading}
                                    label="Role"
                                >
                                    <MenuItem value={"user"} style={{ display: "block" }}>
                                        User
                                    </MenuItem>
                                    <MenuItem value={"admin"} style={{ display: "block" }}>
                                        Admin
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl variant="filled" fullWidth required>
                                <InputLabel id="sel-locale-label" sx={{ ml: 0, alignContent: "left" }}>
                                    {t("com.language")}
                                </InputLabel>
                                <Select
                                    labelId="sel-locale-label"
                                    id="sel-locale"
                                    value={locale}
                                    onChange={(e) => setLocale(e.target.value)}
                                    disabled={loading}
                                    label="Locale"
                                >
                                    <MenuItem value={"en"} style={{ display: "block" }}>
                                        English
                                    </MenuItem>
                                    <MenuItem value={"fr"} style={{ display: "block" }}>
                                        French
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <TimezonesBox disabled={loading} sx={{ width: 300 }} callback={(e) => setZoneInfo(e)} />

                            {error && <Alert severity="error">{error}</Alert>}
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CustomButton name={t("btn.add")} callback={handleSubmit} />
                </DialogActions>
            </BootstrapDialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => {
                    setFirstName("");
                    setLastName("");
                    setOpenSnackbar(false);
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
                    {firstName} {lastName} {t("de.user_created")}
                </Alert>
            </Snackbar>
        </div>
    );
}
