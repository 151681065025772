import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Item from "../../../../components/ui/Item";
import { CustomButton } from "../../../../components/ui/CustomButton";
import Stack from "@mui/material/Stack";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import ChartOption from "./ChartOption";
import StaticValues from "./StaticValues";

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

HighchartsExporting(Highcharts);

export default function GraphMain({ }) {
    const [startDate, setStartDate] = useState(dayjs());
    const [options, setOptions] = useState({});

    const [type, setType] = useState("column");
    const [unit, setUnit] = useState("");
    const [yAxisTitle, setYAxisTitle] = useState("Consumption (gallons)");
    const [interval, setInterval] = useState("hour");

    const theme = useTheme();
    const [screenSize, setScreenSize] = useState("md");

    useEffect(() => {
        // console.log("uef in GraphMain");

        let categories = ["Aug", "Sep"];
        let chartData = [20, 30, 9];

        if (interval == "hour") {
            categories = StaticValues.getHourly().category;
            chartData = StaticValues.getHourly().data;
        } else if (interval == "day") {
            categories = StaticValues.getDaily().category;
            chartData = StaticValues.getDaily().data;
        } else if (interval == "month") {
            categories = StaticValues.getMontly().category;
            chartData = StaticValues.getMontly().data;
        }

        setOptions(ChartOption(yAxisTitle, type, unit, chartData, interval, categories));

    }, [interval, startDate, theme.breakpoints]);

    const handleButtons = (interval) => {
        setInterval(interval);
    };

    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [],
            h = h / 3 - 2;
        path = path.concat(this.circle(w - h, y, h, h), this.circle(w - h, y + h + 4, h, h), this.circle(w - h, y + 2 * (h + 4), h, h));
        return path;
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={4} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <Item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Date"
                                value={startDate}
                                onChange={(newValue) => {
                                    // console.log(newValue);
                                    setStartDate(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Item>
                </Grid>
                <Grid item md={8} sm={12} sx={{ display: "flex", alignItems: "center", justifyContent: screenSize == "xs" ? "center" : "left", width: "100%" }}>
                    <Stack spacing={1} direction={"row"}>
                        <CustomButton name="Hour" callback={() => handleButtons("hour")} toggled={interval == "hour" ? true : false} />
                        <CustomButton name="Day" callback={() => handleButtons("day")} toggled={interval == "day" ? true : false} />
                        <CustomButton name="Month" callback={() => handleButtons("month")} toggled={interval == "month" ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </Grid>
            </Grid>
        </Box>
    );
}
