import ApiService from "Services/apiService";
import _ from "underscore";
import SuperviotConstants from "../SuperviotConstants";

const AttributeService = {
    requestThing: async (thingId, setThing) => {
        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions)
            .then((r) => {
                setThing(r);

                if (r.product && r.product.model) {
                    return r.product.model.id;
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestAttributes: async (modelId) => {
        let requestOptions = {
            url: `/api/models/${modelId}`,
        };

        return ApiService.getData(requestOptions)
            .then((r) => {
                return r.attributes ? r.attributes : undefined;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestRanges: async (thingId, attributes, setUserDefined) => {
        let requestOptions = {
            url: `/v2/thing_range/${thingId}/setting`,
            params: {
                attributeIds: attributes.join(","),
            },
        };

        // console.log(requestOptions);
        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        let userDefinedJson = {};
        result.map((r) => {
            let markJson = {};

            JSON.parse(r.marks).forEach((m, index, array) => {
                // if (index === 0 || index === array.length - 1) {
                //     markJson[m] = ""; // In oder to hide the first and last element on the slider
                // } else {
                markJson[m] = m;
                // }
            });

            // console.log(markJson);

            userDefinedJson[r.attribute.id] = {
                name: r.attribute.name,
                marks: markJson,
                value: JSON.parse(r.marks),
            };
        });

        // console.log(userDefinedJson);
        setUserDefined(userDefinedJson);
    },

    updateRange: async (siteName, thingId, attributeId, trackColors, values, reverse, username, originalMarks, attributeName) => {
        // console.log(trackColors)
        const bgColor = trackColors.map((t) => SuperviotConstants.toString(t.backgroundColor));

        let requestOptions = {
            url: `/v2/thing_range`,
            data: {
                thingId,
                attributeId,
                marks: JSON.stringify(values),
                trackColors: JSON.stringify(bgColor),
                siteName,
                reverse,
            },
        };
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log("values", values);
                // console.log("originalMarks", originalMarks);

                let originalValue = null;
                let changedValue = null;

                // Find original value (value in originalMarks that's not in values)
                originalValue = Object.keys(originalMarks).find((key) => key !== "" && !values.includes(Number(key)));
                if (originalValue) {
                    originalValue = Number(originalValue);
                }

                // Find changed value (new value not in originalMarks)
                changedValue = values.find((value) => !Object.keys(originalMarks).includes(value.toString()));

                // console.log("Original Value:", originalValue, "Changed Value:", changedValue); // New value that wasn't in originalMarks

                if (originalMarks === undefined || changedValue === undefined) {
                    return;
                }

                let message = `Modification seuil pour le paramètre ${attributeName.toLowerCase()} : ancienne valeur =  ${originalValue}, nouvelle valeur = ${changedValue}`;

                let option = {
                    url: `/api/logs/user`,
                    data: {
                        thingId,
                        username: username,
                        message,
                        type: "UPDATE",
                        scope: "STATUS",
                    },
                };
                // console.log(option);

                ApiService.postData(option);
            })
            .catch((e) => {
                // console.error(e);
            });
    },
};
export default AttributeService;
