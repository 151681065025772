import _ from "underscore";
import ApiService from "Services/apiService";
import UtilService from "Services/utilService";

const MeasurementService = {
    requestModel: async (thingId, setModel) => {
        let requestOptions = {
            url: `/api/things/${thingId}/model`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestModel...Response");
                // console.log(response);

                let attributes = [];
                attributes.push({ id: "all", label: "All" });
                response.attributes.forEach((attr) => {
                    attributes.push({
                        id: attr.id,
                        key: attr.id,
                        label: attr.name,
                    });
                });
                setModel(attributes);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requesCustomtModel: async (thingId, setModel) => {
        let requestOptions = {
            url: `/api/things/${thingId}/custom_model/attributes`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requesCustomtModel...Response");
                // console.log(response);

                if (!response.code) {
                    setModel((model) => {
                        response.forEach((e) =>
                            model.push({
                                id: e.id,
                                key: e.id,
                                label: e.name,
                            })
                        );
                        return model;
                    });
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestMeasurements: async (page, pageSize, setRows, thingId, sort, selectedAttributeId, setRowCount, start, end) => {
        if (thingId == undefined) {
            return;
        }

        setRows([]); // reset the rows

        // let filter = `thing.id:eq_${thingId},measure.timestamp:gt_${moment().subtract(1, 'months').unix()}`;
        let filter = `thing.id:eq_${thingId}`;
        if (selectedAttributeId != "all") {
            filter += `,attribute.id:eq_${selectedAttributeId}`;
        }

        filter += `,timestamp:bet_${start}-${end}`;

        let sortStr = "&sort=-timestamp";
        if (sort) {
            const tempSort = sort
                .map((s) => {
                    let order = s.sort == "asc" ? "+" : "-";
                    return order + s.field;
                })
                .join(",");

            sortStr = "&sort=" + tempSort;
        }

        let requestOptions = {
            url: `/api/measures?filter=${filter}&page=${page + 1}&pageSize=${pageSize}&detailed=true${sortStr}`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                let data = [];
                let totalSize = response.totalSize;

                response.data.forEach((ele) => {
                    let obj = {
                        id: ele.id,
                        "attribute.name": ele.attribute.name,
                        value: ele.value + " " + ele.attribute.unit,
                        timestamp: UtilService.formatDMYYHMS(ele.timestamp * 1000),
                    };

                    data.push(obj);
                });

                setRowCount(totalSize);
                setRows(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestLastMeasurements: async (thingId, setLastMeasures) => {
        if (thingId == undefined) {
            return;
        }

        setLastMeasures([]); // reset the rows

        let requestOptions = {
            url: `/api/things/${thingId}/last_measurements`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                let data = [];

                response.forEach((ele) => {
                    let obj = {
                        id: ele.attribute.id,
                        name: ele.attribute.name,
                        value: ele.value + " " + ele.attribute.unit,
                        timestamp: UtilService.formatMDYYHMS(ele.timestamp * 1000),
                    };

                    data.push(obj);
                });

                setLastMeasures(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestSetting: async (thingId, setNumber, setTransmission, setMeasure) => {
        let requestOptions = {
            url: `/v2/pull_endpoint/${thingId}/custom_field`,
            params: {
                name: [
                    "HL_NUMBER",
                    "TX_FREQ",
                    "PAS_MESURE",
                    "CFG_SMS_ON",
                    "CFG_TX_FREQ_GREEN_BLUE",
                    "CFG_TX_FREQ_RED_ORANGE_YELLOW",
                    "CFG_PAS_MESURE_GREEN_BLUE",
                    "CFG_PAS_MESURE_YELLOW",
                    "CFG_PAS_MESURE_ORANGE",
                    "CFG_PAS_MESURE_RED",
                ],
            },
        };

        // console.log(requestOptions);
        setNumber("");

        if (setTransmission) setTransmission("");
        if (setMeasure) setMeasure("");

        return ApiService.getData(requestOptions)
            .then((response) => {
                let obj = {};

                response.forEach((c) => {
                    if (c.name === "HL_NUMBER") {
                        setNumber(c.value);
                        obj.HL_NUMBER = c.value;
                    } else if (c.name === "TX_FREQ" && setTransmission) {
                        setTransmission(c.value);
                    } else if (c.name === "PAS_MESURE" && setMeasure) {
                        setMeasure(c.value);
                    } else {
                        obj[c.name] = c.value;
                    }
                });

                return obj;
            })
            .catch((e) => {
                console.error(e);
            });
    },

    saveSettings: async (thingId, name, value, username, original) => {
        let requestOptions = {
            url: `/v2/pull_endpoint/${thingId}/custom_field`,
            data: [
                {
                    name: name,
                    value: value,
                },
            ],
        };

        // Save settings
        return ApiService.putData(requestOptions)
            .then((response) => {
                // Save logs
                let convertedOriginalValue = original[name];
                if (convertedOriginalValue.includes(":")) {
                    const [hours, minutes] = convertedOriginalValue.split(":");
                    convertedOriginalValue = (parseInt(hours) * 60 + parseInt(minutes)).toString();
                }

                let convertedValue = value;
                // console.log("value", value);

                if (typeof value === "string" && value.includes(":")) {
                    const [hours, minutes] = value.split(":");
                    convertedValue = (parseInt(hours) * 60 + parseInt(minutes)).toString();
                }

                let message = "";
                switch (name) {
                    case "HL_NUMBER":
                        message = `Modification de téléphone de la station : ancien numéro = ${convertedOriginalValue}, nouveau numéro = ${value}`;
                        break;
                    case "CFG_SMS_ON":
                        if (value === true) {
                            message = `[activation] communication descendant`;
                        } else {
                            message = `[desactivation] communication descendant`;
                        }

                        break;

                    case "CFG_TX_FREQ_GREEN_BLUE":
                        message = `Modification de la fréquence de transmission pour le niveau bleu-vert : ancienne valeur = ${convertedOriginalValue}, nouvelle valeur = ${convertedValue}`;
                        break;
                    case "CFG_TX_FREQ_RED_ORANGE_YELLOW":
                        message = `Modification de la fréquence de transmission pour le niveau orange/jaune/rouge : ancienne valeur = ${convertedOriginalValue}, nouvelle valeur = ${convertedValue}`;
                        break;
                    case "CFG_PAS_MESURE_GREEN_BLUE":
                        message = `Modification de pas de mesure pour le niveau bleu-vert : ancienne valeur = ${convertedOriginalValue}, nouvelle valeur = ${convertedValue}`;
                        break;
                    case "CFG_PAS_MESURE_RED":
                        message = `Modification de pas de mesure pour le niveau rouge : ancienne valeur = ${convertedOriginalValue}, nouvelle valeur = ${convertedValue}`;
                        break;
                    case "CFG_PAS_MESURE_YELLOW":
                        message = `Modification de pas de mesure pour le niveau jaune : ancienne valeur = ${convertedOriginalValue}, nouvelle valeur = ${convertedValue}`;
                        break;
                    case "CFG_PAS_MESURE_ORANGE":
                        message = `Modification de pas de mesure pour le niveau orange : ancienne valeur = ${convertedOriginalValue}, nouvelle valeur = ${convertedValue}`;
                        break;
                    default:
                        message = `${name} updated to ${value}`;
                }

                let option = {
                    url: `/api/logs/user`,
                    data: {
                        thingId,
                        username: username,
                        message,
                        type: "UPDATE",
                        scope: "STATUS",
                    },
                };
                // console.log(option);

                ApiService.postData(option);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    downloadMeasurements: async (thingId, start, end, sort, selectedAttributeId, language) => {
        let requestOptions = {
            url: `/v2/report/measure`,
            params: {
                thingId,
                start,
                end,
                sort: sort.map((s) => `${s.field}:${s.sort}`).join(","),
                language,
            },
        };

        if (selectedAttributeId && selectedAttributeId !== "all") {
            requestOptions.params.attributeId = selectedAttributeId;
        }

        const response = await ApiService.downloadCsv(requestOptions);
        return response;
    },
};

export default MeasurementService;
