import ApiService from "Services/apiService";

const CompanyService = {
    addCompany: async (companyName, days) => {
        let requestOptions = {
            url: `/v2/companies/retention`,
            data: {
                companyName: companyName.toUpperCase(),
                retentionDays: days,
            },
        };

        const response = await ApiService.postData(requestOptions);
        console.log(response);

        return response;
    },
};

export default CompanyService;
