import { useEffect, useState } from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { DataGridPro, GridToolbarContainer } from "@mui/x-data-grid-pro";
import ExportMenu from "../deviceExplorer/measurements/ExportMenu";
import BizService from "./BizService";
import BizColumns from "./BizColumns";
import { frFR } from "@mui/x-data-grid/locales";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function ListSensorPerMonth({ companyId }) {
    const [paging, setPaginationModel] = useState({ page: 0, pageSize: 15 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState([{ field: "baseMonth", sort: "desc" }]);
    const { t, i18n } = useTranslation();

    const columns = BizColumns.getColumns(t);

    useEffect(() => {
        // console.log("useEffect in ListSensorPerMonth");

        BizService.requetThingHistory(companyId, paging.page, paging.pageSize, sort, setRows, setRowCount);
    }, [companyId, paging.page, paging.pageSize, sort]);

    const handleSortModelChange = (params) => {
        // console.log("handleSortModelChange ...");
        setSort(params);
    };

    const handleRowClick = () => {
        // console.log("handleRowClick ...");
    };

    const handleSelectionModel = (model) => {
        // console.log("handleSelectionModel ...");
        // console.log(model);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                {/* <GridToolbarColumnsButton />
                <GridToolbarDensitySelector /> */}
                {/* <GridToolbarExport /> */}
                <ExportMenu callback={handleReport} />
            </GridToolbarContainer>
        );
    }

    const handleReport = (reportType) => {
        // console.log(reportType);
        BizService.requestReport(sort, reportType, i18n.language);
    };

    return (
        <Box sx={{ mt: 1 }}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <DataGridPro
                        rows={rows}
                        rowCount={rowCount}
                        columns={columns}
                        paginationModel={paging}
                        onPaginationModelChange={setPaginationModel}
                        pagination={true}
                        paginationMode="server"
                        sortingMode="server"
                        pageSizeOptions={[15, 50, 100]}
                        onSortModelChange={handleSortModelChange}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        onRowClick={handleRowClick}
                        checkboxSelection={false}
                        onSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                        density="compact"
                        // filterModel={{
                        // 	items: [
                        // 		{ columnField: 'state', operatorValue: 'contains', value: filterState },
                        // 	],
                        // }}
                        filterMode="server"
                        disableMultipleColumnsFiltering={true}
                        // onFilterModelChange={handleFilterModelChange}
                        // onColumnVisibilityChange={handleVisibilityChange}
                        disableColumnReorder={true}
                        height="100%"
                        sx={{
                            "& .MuiTablePagination-selectLabel": {
                                marginTop: 2,
                            },
                            "& .MuiTablePagination-displayedRows": {
                                marginTop: 2,
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    username: false,
                                },
                            },
                        }}
                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                        style={{ height: "calc(100vh - 220px)" }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
