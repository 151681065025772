import ApiService from "Services/apiService";
import UtilService from "Services/utilService";

const MeasurementServiceV2 = {
    requestData: async (keyword, page, pageSize, setRows, setRowCount, sort) => {
        const requestOptions = {
            url: "/v2/things",
            params: {
                page: page,
                pageSize: pageSize,
                keyword: encodeURI(keyword),
                sort: sort.map((s) => `${s.field}:${s.sort}`).join(","),
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions).catch((e) => {
            console.log(e);
            throw e;
        });
        const mapped = result.content.map(mapRow);

        // console.log("mapped", mapped);

        if (setRows) setRows(mapped);
        if (setRowCount) setRowCount(result.totalElements);

        // console.log("mappedm", mapped);
        return mapped;
    },

    requestByUser: async (keyword, page, pageSize, setRows, setRowCount, sort, cloudUserId) => {
        const requestOptions = {
            url: "/v2/things/assigned_by_user",
            params: {
                page: page,
                pageSize: pageSize,
                keyword: encodeURI(keyword),
                sort: sort.map((s) => `${s.field}:${s.sort}`).join(","),
                cloudUserId,
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions).catch((e) => {
            console.log(e);
            throw e;
        });
        // console.log(result);

        if (result.content) {
            const mapped = result.content.map(mapRow);
            // console.log("mapped", mapped);

            if (setRows) setRows(mapped);
            if (setRowCount) setRowCount(result.totalElements);

            return mapped;
        } else {
            if (setRows) setRows([]);
            if (setRowCount) setRowCount(0);
        }
    },

    requestByCompany: async (keyword, page, pageSize, setRows, setRowCount, sort, company) => {
        const requestOptions = {
            url: "/v2/things/assigned_by_company",
            params: {
                page: page,
                pageSize: pageSize,
                keyword: encodeURI(keyword),
                sort: sort.map((s) => `${s.field}:${s.sort}`).join(","),
                company,
            },
        };
        // console.log(requestOptions);

        const result = await ApiService.getData(requestOptions);
        const mapped = result.content.map(mapRow);

        if (setRows) setRows(mapped);
        if (setRowCount) setRowCount(result.totalElements);

        // console.log("mappedm", mapped);
        return mapped;
    },
};

const mapRow = (r) => {
    // Extracting relevant properties using destructuring
    const { id, fixedName, lastMessageDate: rawLastMessageDate, product: rawProduct, status, tags, site, company, customFields } = r;

    // Mapping each field to its corresponding value
    return {
        id,
        fixedName,
        lastMessageDate: rawLastMessageDate ? UtilService.formateDate(rawLastMessageDate * 1000) : "",
        product: rawProduct ? rawProduct.name : "",
        status,
        "thing_tag_view.aggTag": tags ? tags.map((t) => t.tag).join(",") : "",
        "site.name": site ? site.name : "",
        manufacturer: rawProduct ? rawProduct.manufacturer.name : "",
        "company.name": company,
        connectivityType: rawProduct ? rawProduct.connectivityTypes[0] : "",
        name: r.name,
        customFields,
    };
};

export default MeasurementServiceV2;
