import { useEffect, useContext } from "react";
import _ from "underscore";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SettingContext from "Providers/SettingContext";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Item from "../../../components/ui/Item";

export default function ProductDetail(props) {
    const { t, i18n } = useTranslation();
    const setting = useContext(SettingContext);
    const { product } = props;
    const appId = props.appId;

    useEffect(() => {
        // do nothing
    }, []);

    return (
        <div style={{ width: "100%" }}>
            {_.isEmpty(product) && (
                <Paper
                    elevation={0}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    {t("de.no_product")}
                </Paper>
            )}
            {!_.isEmpty(product) && (
                <Paper elevation={0}>
                    <Accordion defaultExpanded={true}>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.product_id")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {product.productId}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.connectivity")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {product.connectivityTypes}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.commercial_name")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {product.commercialName}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.product_reference")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {product.reference}
                                    </Paper>
                                </Grid>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.tags")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {product.tag}
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={0}>
                                        <Item>{t("catalog.product_description")}</Item>
                                        <Item>
                                            <TextField
                                                id="standard-multiline-static"
                                                // label="Multiline"
                                                multiline
                                                rows={4}
                                                defaultValue="Default Value"
                                                variant="outlined"
                                                value={product.description}
                                                fullWidth
                                                sx={{
                                                    "& legend": { display: "none" },
                                                    "& fieldset": { top: 0 },
                                                }}
                                            />
                                        </Item>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                            <Typography>{t("catalog.data_model")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.name")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {product.model}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                            <Typography>{t("catalog.vendor")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.name")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {product.manufacturer}
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.catalog")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {product.manufacturerCategory}
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        {t("catalog.web")}
                                    </Paper>
                                </Grid>
                                <Grid item xs={9}>
                                    <Paper elevation={0} sx={{ textAlign: "left" }}>
                                        <a href={product.infoLink} target="_blank">
                                            {product.infoLink}
                                        </a>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            )}
        </div>
    );
}
