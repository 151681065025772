import ApiService from "Services/apiService";
import _ from "underscore";

const CloudUserService = {
    requestCloudUserV2: async (page, pageSize, setRows, setRowCount, sort, company, gridFilter) => {
        let requestOptions = {
            url: `/v2/cloud_user`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
                domainName: process.env.REACT_APP_DOMAIN_NAME,
            },
        };

        if (company) {
            requestOptions.params["company"] = company;
        }

        if (gridFilter?.items?.length > 0) {
            const filterQueries = gridFilter.items.map((item) => {
                const operatorMap = {
                    contains: "like",
                    equals: "eq",
                    startsWith: "startswith",
                    endsWith: "endswith",
                    isEmpty: "empty",
                    isNotEmpty: "notempty",
                    isAnyOf: "in",
                    gt: "gt",
                    gte: "gte",
                    lt: "lt",
                    lte: "lte",
                };

                const operator = operatorMap[item.operator] || item.operator;
                return `${item.field}:${operator}_${item.value}`;
            });

            requestOptions.params["filter"] = filterQueries.join(`;${gridFilter.logicOperator};`);
        }

        // console.log(requestOptions);
        return ApiService.getData(requestOptions)
            .then((res) => {
                // console.log("response in requestCloudUser");
                // console.log(res);

                let mapped = res.content.map((r) => {
                    // console.log("///----");
                    // console.log(r);

                    let isSubCompany = r.company.parent ? true : false;

                    return {
                        id: r.id,
                        email: r.email,
                        username: r.username,
                        company: r.company.name,
                        role: r.role,
                        enabled: r.enabled,
                        isSubCompany: isSubCompany,
                    };
                });

                setRows(mapped);
                setRowCount(res.totalElements);
            })
            .catch((e) => console.error(e));
    },

    requestCloudUser: async (cloudUserId) => {
        let requestOptions = {
            url: `/v2/cloud_user/${cloudUserId}`,
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions).then((res) => {
            // console.log(res);
            return res;
        });
    },

    changeGroup: async (username, groups) => {
        let requestOptions = {
            url: "/api/cloud_user/change_group",
            data: {
                username: username,
                groups: [groups],
            },
        };

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            return;
        });
    },

    changeRole: async (username, role) => {
        let requestOptions = {
            url: "/api/cloud_user/change_role_dev_exp",
            data: {
                username: username,
                role: role,
                domainName: process.env.REACT_APP_DOMAIN_NAME,
            },
        };

        // console.log("changeRole in CloudUserService");
        // console.log(requestOptions);

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            // console.log(res);
            return;
        });
    },

    changeRoleOnPremise: async (cloudUserId, role) => {
        let requestOptions = {
            url: "/api/cloud_user/change_role_dev_exp",
            data: {
                cloudUserId,
                role: role,
                domainName: process.env.REACT_APP_DOMAIN_NAME,
            },
        };

        // console.log("changeRole in CloudUserService");
        // console.log(requestOptions);

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            // console.log(res);
            return;
        });
    },

    changeEnabled: async (username, enabled) => {
        let requestOptions = {
            url: "/api/cloud_user/enabled",
            data: {
                username: username,
                enabled: enabled,
            },
        };

        // console.log("changeRole in CloudUserService");
        // console.log(requestOptions);

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            // console.log(res);
            return;
        });
    },

    changeEnabledOnPremise: async (cloudUserId, enabled) => {
        let requestOptions = {
            url: "/api/cloud_user/enabled",
            data: {
                cloudUserId: cloudUserId,
                enabled: enabled,
            },
        };

        // console.log("changeRole in CloudUserService");
        // console.log(requestOptions);

        return ApiService.putData(requestOptions).then((res) => {
            // do nothing
            // console.log(res);
            return;
        });
    },

    createUser: async (email, firstName, lastName, company, role, locale, zoneinfo, appId) => {
        let requestOptions = {
            url: "/api/cloud_user/create",
            data: {
                email: email.toLowerCase(),
                name: firstName,
                familyName: lastName,
                company: company,
                authority: "PTH",
                locale: locale,
                zoneinfo: zoneinfo,
                // Users are assigned to "APPLICATION_XX" groups based on company apps.
                // If the company has no apps, users have no application groups.
                // If the company has apps, users are assigned to the same app groups.
                groups: ["PTH", "COMPANY_" + company],
                domainName: process.env.REACT_APP_DOMAIN_NAME,
                role: role,
            },
        };

        if (role === "admin") {
            requestOptions.data.groups.push("CLIENT_ADMIN");
        }

        if (appId) {
            requestOptions.data.groups.push("APPLICATION_" + appId.toUpperCase());
        } else {
            requestOptions.data.groups.push("APPLICATION_DEVICE_EXPLORER");
        }

        // console.log("createUser in CloudUserService");
        // console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((res) => {
                // console.log(res);
                if (res.code && res.code === "failure") {
                    throw new Error(res.message);
                }
                return res;
            })
            .catch((e) => {
                console.error(e);
                return e;
            });
    },

    createUserOnPremise: async (email, firstName, lastName, company, role, locale, zoneinfo, appId) => {
        let requestOptions = {
            url: "/v2/cloud_user/create",
            data: {
                email: email.toLowerCase(),
                name: firstName,
                familyName: lastName,
                company: company,
                authority: "PTH",
                locale: locale,
                zoneinfo: zoneinfo,
                // Users are assigned to "APPLICATION_XX" groups based on company apps.
                // If the company has no apps, users have no application groups.
                // If the company has apps, users are assigned to the same app groups.
                groups: ["PTH", "COMPANY_" + company],
                domainName: process.env.REACT_APP_DOMAIN_NAME,
                role: role,
            },
        };

        if (role === "admin") {
            requestOptions.data.groups.push("CLIENT_ADMIN");
        }

        let apps = [];
        if (appId) {
            apps.push("APPLICATION_" + appId.toUpperCase());
        } else {
            apps.push("APPLICATION_DEVICE_EXPLORER");
        }
        requestOptions.data["applications"] = apps;

        // console.log("createUserOnPremise in CloudUserService");
        // console.log(requestOptions);

        return ApiService.postData(requestOptions)
            .then((res) => {
                return res;
            })
            .catch((e) => {
                console.error(e);
                return e;
            });
    },

    removeUser: async (userId) => {
        let requestOptions = {
            url: `/api/cloud_user/${userId}`,
        };
        // console.log("requestOptions")
        // console.log(requestOptions)
        return ApiService.deleteData(requestOptions);
    },

    requestThings: async (keyword, pageSize, page, setRows, setRowCount) => {
        let orFilter = [
            {
                property: [
                    "thing.name",
                    "thing.fixedName",
                    // 'thing.status',
                    // 'connectivity.status',
                    // 'connectivity.type',
                    // 'product.name',
                    // 'product.reference',
                    // 'product.model.name',
                    // 'product.manufacturer.name',
                    // 'site.name',
                    // 'tags',
                    // 'company'
                ],
                operator: "ilike",
                value: keyword,
            },
        ];

        // let filter = "thing.company:eq_ATIM";

        let requestOptions = {
            url: `/api/things?pageSize=${pageSize}&page=${page}&orFilter=${encodeURI(JSON.stringify(orFilter))}&detailed=true`,
        };

        // console.log("-------")
        // console.log(requestOptions);

        ApiService.getData(requestOptions).then((res) => {
            let mapped = res.data.map((r) => {
                // console.log(r)

                return {
                    id: r.id,
                    thing_name: r.name,
                    thing_fixedName: r.fixedName,
                    status: r.status,
                    vendor: r.product.manufacturer.name,
                    product_name: r.product.name,
                };
            });

            setRows(mapped);
            setRowCount(res.totalSize);
        });
    },

    findThingsBySelectedCompany: async (keyword, selectedCompany, pageSize, page, setRows, setRowCount, selectedGroupName) => {
        let requestOptions = {
            url: `/v2/things/by_company`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: "fixedName:asc",
                keyword: keyword,
            },
        };

        if (selectedCompany) {
            requestOptions.params["company"] = selectedCompany;
        }

        // console.log("findThingsBySelectedCompany...")
        // console.log(requestOptions);

        setRows([]);
        setRowCount(0);

        const result = await ApiService.getData(requestOptions);
        // console.log("/////");
        // console.log(result);

        // console.log("selectedGroupName....." + selectedGroupName)

        if (!result.message) {
            const mapped = result.content.map((r) => {
                // console.log("assignedCloudGroups: " + r.assignedCloudGroups)

                let selectable = true;
                // if (r.assignedCloudGroups.length == 0) {
                //     selectable = true;
                // } else {
                //     if (r.assignedCloudGroups.includes(selectedGroupName)) {
                //         selectable = true;
                //     } else {
                //         // This thing is already assigned to another group, so selectable = true
                //         selectable = false;
                //     }
                // }

                return {
                    id: r.id,
                    thing_name: r.name,
                    thing_fixedName: r.fixedName,
                    status: r.status,
                    vendor: r.product ? r.product.manufacturer.name : "",
                    product_name: r.product ? r.product.name : "",
                    assigned_users: r.assignedCloudUsers.join(","),
                    assigned_company: r.assignedCloudGroups.join(","),
                    selectable: selectable,
                };
            });

            // console.log("mapped......")
            // console.log(mapped)

            setRows(mapped);
            setRowCount(result.totalElements);
        }
    },

    findAssignedThingsByCompanyId: async (keyword, pageSize, page, setRows, setRowCount, userCompanyId, selectedCompanyId) => {
        let requestOptions = {
            url: "/v2/company_thing",
            params: {
                page: page,
                pageSize: pageSize,
                sort: "company.name:asc",
                keyword: keyword,
                companyId: userCompanyId,
            },
        };

        if (selectedCompanyId) {
            requestOptions.params["selectedCompanyId"] = selectedCompanyId;
        }

        // console.log("findAssignedThingsByCompanyId-------")
        // console.log(requestOptions);

        setRows([]);
        setRowCount(0);

        const result = await ApiService.getData(requestOptions);
        // console.log("findAssignedThingsByCompanyId.....");
        // console.log(result);

        // console.log("selectedGroupName....." + selectedGroupName)

        if (!result.message) {
            const mapped = result.content.map((r) => {
                // console.log("assignedCloudGroups: " + r.assignedCloudGroups)

                let selectable = true;
                // if (r.assignedCloudGroups.length == 0) {
                //     selectable = true;
                // } else {
                //     if (r.assignedCloudGroups.includes(selectedGroupName)) {
                //         selectable = true;
                //     } else {
                //         // This thing is already assigned to another group, so selectable = true
                //         selectable = false;
                //     }
                // }

                return {
                    id: r.thing.id,
                    thing_name: r.thing.name,
                    thing_fixedName: r.thing.fixedName,
                    status: r.thing.status,
                    vendor: r.thing.product ? r.thing.product.manufacturer.name : "",
                    product_name: r.thing.product ? r.thing.product.name : "",
                    assigned_users: r.assignedUser ? r.assignedUser.join(",") : "",
                    assigned_company: r.assignedCompany ? r.assignedCompany.map((c) => c.name).join(",") : "",
                    selectable: selectable,
                };
            });

            // console.log("mapped......")
            // console.log("mapped", mapped);
            // console.log("result.totalElements", result.totalElements);

            setRows(mapped);
            setRowCount(result.totalElements);
        } else {
            setRows([]);
            setRowCount(0);
        }
    },

    requestThingByCloudUserId: async (cloudUserId) => {
        // console.log("requestThingByCloudUserId...");

        let requestOptions = {
            url: `/api/cloud_user/${cloudUserId}/things`,
        };

        // const result = await ApiService.getData(requestOptions)
        //     .then((r) => console.log(r))
        //     .catch((e) => console.error(e));

        const result = await ApiService.getData(requestOptions);
        // console.log(result);

        return result;
    },

    assignThings: async (selectedUserId, thingIds, rowIds) => {
        let requestOptions = {
            url: `/api/cloud_user/assign`,
            data: {
                cloudUserId: selectedUserId,
                things: thingIds,
                rowIds: rowIds,
            },
        };
        // console.log(requestOptions)

        const result = await ApiService.putData(requestOptions);
        // console.log(result);
    },
};

export default CloudUserService;
