import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "underscore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import validator from "validator";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import Tags from "../tag/Tags";
import ElectricDetail from "./ElectricDetail";
import CustomerDetail from "./CustomerDetail";
import { LicenseInfo } from "@mui/x-license-pro";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChartDetail from "./ChartDetail";
import AssociateAttribute from "./AssociateAttribute";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import SensorDetailService from "./SensorDetailService";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LanIcon from "@mui/icons-material/Lan";
import moment from "moment";
import SiteDetail from "./SiteDetail";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function SensorDetailDialog({ open, setOpen, thingId, entity, setReload }) {
    const [thingName, setThingName] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const [thing, setThing] = useState();
    const [tags, setTags] = useState();
    const [reloadThis, setReloadThis] = useState();

    const handleTabs = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleClose = () => {
        setOpen(false);
        // closeDialog(isSaved);

        setTabIndex(0);
    };

    useEffect(() => {
        // console.log("useEffect in SensorDetailDialog...");
        // console.log(thingId);
        SensorDetailService.requestThing(thingId).then((r) => {
            // console.log(r);

            setThing(r);
            setThingName(r.fixedName);
            setTags(r.tags);
            // setReload(moment().unix());
        });
    }, [open, entity, reloadThis, thingId]);

    const handleUpdate = () => {
        if (validator.isEmpty(thingName)) {
            return;
        }
        setIsSaving(true); 

        SensorDetailService.saveThingName(thingId, thingName).then((r) => {
            setIsSaving(false);
            setReload();
        });
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">
                Meter's details
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
                <Box sx={{ flexGrow: 1, m: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                            <Stack spacing={2} direction={"row"}>
                                <TextField
                                    id="txtName"
                                    variant="standard"
                                    sx={{ width: 500 }}
                                    value={thingName}
                                    onChange={(e) => {
                                        // console.log(e);
                                        setThingName(e.target.value);
                                    }}
                                />
                                <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
                                    <IconButton edge="end" color="primary" onClick={handleUpdate} size="large">
                                        {!isSaving && <SaveIcon />}
                                        {isSaving && (
                                            <Box sx={{ display: "flex" }}>
                                                <CircularProgress size={20} />
                                            </Box>
                                        )}
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                            {thing && <Tags selectedThingId={thing.id} selectedCompany={"PTH"} thingTags={tags}></Tags>}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={0}>
                                <Grid container spacing={0} sx={{ p: 0, m: 0 }}>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Tabs value={tabIndex} onChange={handleTabs} aria-label="list or map" sx={{ m: 0 }}>
                                                <Tab icon={<LanIcon size={21} color={"#666666"} />} />
                                                <Tab icon={<HomeWorkIcon size={21} color={"#666666"} />} />
                                                <Tab icon={<ElectricBoltIcon size={21} color={"#666666"} />} />
                                                <Tab icon={<BarChartIcon size={21} color={"#666666"} />} hidden />
                                                <Tab icon={<ViewTimelineIcon size={21} color={"#666666"} />} />
                                            </Tabs>
                                        </Box>
                                        {tabIndex === 0 && <Box sx={{ marginTop: "0px", width: "200px", height: "0px" }}>&nbsp;</Box>}
                                    </Grid>

                                    {tabIndex == 0 && (
                                        <Grid item xs={12} style={{ padding: "0px", width: 400, height: 400 }}>
                                            <CustomerDetail
                                                entity={entity}
                                                thing={thing}
                                                selectedThingId={thing ? thing.id : undefined}
                                                setReload={() => setReloadThis(moment().unix())}
                                            />
                                        </Grid>
                                    )}
                                    {tabIndex === 1 && (
                                        <Grid item xs={12} style={{ padding: "0px", width: 400, height: 400 }}>
                                            <SiteDetail entity={entity} setReload={setReload} />
                                        </Grid>
                                    )}
                                    {tabIndex === 2 && (
                                        <Grid item xs={12} style={{ padding: "0px", width: 400, height: 400 }}>
                                            <ElectricDetail entity={entity} />
                                        </Grid>
                                    )}
                                    {tabIndex === 3 && (
                                        <Grid item xs={12} style={{ padding: "0px", width: 400, height: 400 }}>
                                            <ChartDetail entity={entity} />
                                        </Grid>
                                    )}
                                    {tabIndex === 4 && (
                                        <Grid item xs={12} style={{ padding: "0px", width: 400, height: 400 }}>
                                            <AssociateAttribute entity={entity} setOpen={setOpen} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
