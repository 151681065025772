import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { frFR } from "@mui/x-data-grid/locales";
import { SubColumns } from "./SubColumns";
import { useTranslation } from "react-i18next";
import { CustomButton } from "Components/ui/CustomButton";
import CreateSubscriptionDialog from "./CreateSubscriptionDialog";
import { DataGridPro, GridToolbarContainer } from "@mui/x-data-grid-pro";
import moment from "moment";
import SubService from "./SubService";
import ExportMenu from "Components/grid/ExportMenu";
import { AppId } from "Components/applications/ApplicationData";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

export default function SubscriptionMain({ companyId, companyName }) {
    const [paging, setPaging] = useState({ page: 0, pageSize: 15 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState([{ field: "startDate", sort: "asc" }]);
    const { t, i18n } = useTranslation();

    const [reload, setReload] = useState();
    const [openSubscription, setOpenSubscription] = useState(false);

    useEffect(() => {
        // console.log("uef in SubscriptionMain");
        // console.log(companyId);

        SubService.requestSub(paging.page, paging.pageSize, sort, setRowCount, companyId, setRows);
    }, [companyId, companyName, reload, paging.page, paging.pageSize, sort]);

    const handleSortModelChange = (params) => {
        // console.log("handleSortModelChange ...");
        setSort(params);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                {/* <GridToolbarColumnsButton />
                <GridToolbarDensitySelector /> */}
                {/* <GridToolbarExport /> */}
                <ExportMenu callback={handleReport} />
            </GridToolbarContainer>
        );
    }

    const handleReport = (reportType) => {
        SubService.requestReport(reportType, companyId);
    };

    const handleCheckBox = (id, checked) => {
        SubService.setAutoRenewal(id, checked).then(() => {
            setReload(moment());
        });
    };
    const columns = SubColumns(t, handleCheckBox);

    return (
        <Box sx={{ mt: 0 }}>
            <CustomButton
                name={t("btn.subscription")}
                callback={() => {
                    if (companyName) {
                        setOpenSubscription(true);
                    }
                }}
            />
            <DataGridPro
                rows={rows}
                rowCount={rowCount}
                columns={columns}
                paginationModel={paging}
                onPaginationModelChange={setPaging}
                pagination={true}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[15, 50, 100]}
                onSortModelChange={handleSortModelChange}
                slots={{
                    toolbar: CustomToolbar,
                }}
                // onRowClick={handleRowClick}
                checkboxSelection={false}
                // onSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                density="compact"
                // filterModel={{
                // 	items: [
                // 		{ columnField: 'state', operatorValue: 'contains', value: filterState },
                // 	],
                // }}
                filterMode="server"
                disableMultipleColumnsFiltering={true}
                // onFilterModelChange={handleFilterModelChange}
                // onColumnVisibilityChange={handleVisibilityChange}
                disableColumnReorder={true}
                height="100%"
                sx={{
                    "& .MuiTablePagination-selectLabel": {
                        marginTop: 2,
                    },
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: 2,
                    },
                    mt: 2,
                    "& .MuiDataGrid-virtualScroller": {
                        overflowX: "hidden !important",
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            username: false,
                        },
                    },
                }}
                localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                style={{ height: "calc(100vh - 220px)" }}
                hideFooterPagination={false}
                hideFooter={false}
            />

            <CreateSubscriptionDialog
                open={openSubscription}
                setOpen={setOpenSubscription}
                callback={() => setReload(moment().unix())}
                company={companyName}
                selectedCompanyId={companyId}
                appId={AppId.BIZ}
            />
        </Box>
    );
}
