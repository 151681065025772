import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { CustomButton } from "Components/ui/CustomButton";
import RoomService from "./RoomService";
import Stack from "@mui/material/Stack";
import { AppId } from "Components/applications/ApplicationData";

export default function RoomContactortSetting({ open, setOpen, entity, contactSetting }) {
    const [delayThreshold, setDelayThreshold] = useState(0);
    const [notifyOnClose, setNotifyOnClose] = useState(false);
    const [emailNotify, setEmailNotify] = useState(false);
    const [smsNotify, setSmsNotify] = useState(false);

    useEffect(() => {
        // console.log("entity", entity);
        // console.log("contactSetting", contactSetting);

        if (contactSetting) {
            setDelayThreshold(contactSetting.delayThreshold);
            setNotifyOnClose(contactSetting.notifyOnClose);
            setEmailNotify(contactSetting.enableEmail);
            setSmsNotify(contactSetting.enableSms);
        }

        // RoomService.requestNotifications(setEmailNotify, setSmsNotify, AppId.EMERA_V2);
    }, [contactSetting]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // console.log("handleSave", delayThreshold);
        RoomService.saveSetting(entity.thingId, delayThreshold, notifyOnClose, emailNotify, smsNotify ).then(() => setOpen(false));
    };

    const handleDelayChange = (e) => {
        const value = Math.min(Math.max(0, parseInt(e.target.value) || 0), 1440);
        setDelayThreshold(value);
    };

    const handleEmailNotifyChange = (e) => {
        setEmailNotify(e.target.checked);
        RoomService.saveSetting(entity.thingId, delayThreshold, notifyOnClose, e.target.checked, smsNotify).then(() => setOpen(false));
    };

    const handleSmsNotifyChange = (e) => {
        setSmsNotify(e.target.checked);
        RoomService.saveSetting(entity.thingId, delayThreshold, notifyOnClose, emailNotify, e.target.checked).then(() => setOpen(false));
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">
                {entity?.fixedName}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ flexGrow: 1, m: 2 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }}>
                            <Typography variant="body1">Open delay threshold</Typography>
                            <TextField
                                label=""
                                type="number"
                                value={delayThreshold}
                                onChange={handleDelayChange}
                                fullWidth
                                size="small"
                                sx={{ width: "100px" }}
                                inputProps={{
                                    style: { textAlign: "right" },
                                    min: 0,
                                    max: 1440,
                                }}
                            />
                            <Typography variant="body1">minutes</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">Alert</Typography>
                            <FormControlLabel
                                control={<Checkbox checked={notifyOnClose} onChange={(e) => setNotifyOnClose(e.target.checked)} />}
                                label=" door close after delay"
                                sx={{ ml: 0 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">Notifications</Typography>
                            <Stack direction="row" spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox checked={emailNotify} onChange={handleEmailNotifyChange} />}
                                    label="Email"
                                    sx={{ ml: 0 }}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={smsNotify} onChange={handleSmsNotifyChange} />}
                                    label="SMS"
                                    sx={{ ml: 0 }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton name="OK" callback={handleSave} />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
