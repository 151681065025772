import ApiService from "Services/apiService";
import fileDownload from "js-file-download";

const DatabaseService = {
    requetDatabseUsage: async (companyId, setTotalSize, setUsageSize, setRows) => {
        let requestOptions = {
            url: `/v2/companies/${companyId}/database_usage`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            let totalSize = 0;
            let sizeByCompany = 0;
            let monthlyData = {};
            let totalSizeByMonth = 0;

            r.forEach((e) => {
                totalSize += e.totalSize;
                sizeByCompany += e.sizeByCompany;

                // Process monthly data
                e.monthly.forEach((m) => {
                    const key = `${m.year}-${m.month.toString().padStart(2, "0")}`;
                    if (!monthlyData[key]) {
                        monthlyData[key] = {
                            id: key,
                            year: m.year,
                            month: m.month,
                            sizeByMonth: 0,
                            rowCount: 0,
                        };
                    }
                    monthlyData[key].sizeByMonth += m.sizeByMonth;
                    monthlyData[key].rowCount += m.rowCount;
                    totalSizeByMonth += m.sizeByMonth;
                });
            });

            // console.log("Total size by month:", totalSizeByMonth);

            setTotalSize(totalSize);
            setUsageSize(sizeByCompany);
            setRows(Object.values(monthlyData).sort((a, b) => b.year - a.year || b.month - a.month));
        });
    },

    getCompany: async (companyId) => {
        let requestOptions = {
            url: `/api/companies/${companyId}`,
        };

        return ApiService.getData(requestOptions);
    },

    updateRetention: async (companyId, days) => {
        let requestOptions = {
            url: `/v2/companies/retention`,
            method: "PUT",
            data: { companyId, retentionDays: days },
        };

        return ApiService.putData(requestOptions);
    },
};

export default DatabaseService;
