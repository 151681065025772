import * as minio from "minio";

const TestService = {
    uploadDoc: async (key, file) => {
        try {
            const minioClient = new minio.Client({
                endPoint: "eso.direnpf.org",
                port: 9000,
                useSSL: false,
                accessKey: "Kl49kSw5PIlCIVYCKmT6",
                secretKey: "mVYXb7PzYAoVafcjmbkQPodPK0dqekZSHmeZeCEL",
            });
            console.log("Hi")

            // Check if bucket exists first
            const bucketExists = await minioClient.bucketExists("bucket-tahiti");
            console.log("bucketExists", bucketExists);

            if (!bucketExists) {
                throw new Error("Bucket 'bucket-tahiti' does not exist");
            }

            const arrayBuffer = await file.arrayBuffer();
            const buffer = Buffer.from(arrayBuffer);

            const result = await minioClient.putObject("bucket-tahiti", key, buffer, file.size, {
                "Content-Type": file.type,
                // Add metadata to help with debugging
                "x-amz-meta-original-name": file.name,
                "x-amz-meta-upload-time": new Date().toISOString()
            });

            // If we get here, the upload was successful
            if (result) {
                return { success: true, etag: result };
            } else {
                throw new Error("Upload completed but no ETag received");
            }
        } catch (error) {
            // More specific error handling
            if (error.code === 'UnknownError' && error.message === '204') {
                // This might actually be a success case
                return { success: true, message: 'File appears to have been uploaded successfully' };
            }
            
            console.error("Error details:", {
                code: error.code,
                message: error.message,
                requestId: error.amzRequestid,
                region: error.amzBucketRegion
            });
            
            throw error;
        }
    },
};

export default TestService;
