import React, { useState, useEffect } from "react";
import _ from "underscore";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ThresholdDialogV2Input from "./ThresholdDialogV2Input";
import ThresholdServiceV2 from "./ThresholdServiceV2";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("53264e234090f26bf650c109bcf9fc2fTz0xMDUxMDMsRT0xNzY5Mjg1NDAxMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
        field: "startTime",
        headerName: "Start time",
        flex: 1,
        editable: false,
        align: "left",
        headerAlign: "left",
    },
    {
        field: "endTime",
        headerName: "End time",
        flex: 1,
        editable: false,
        sortable: true,
        align: "left",
        headerAlign: "left",
    },
    {
        field: "isAllTime",
        headerName: "All Time",
        type: "boolean",
        flex: 1,
        editable: false,
        sortable: true,
        hide: true,
    },
    {
        field: "lowValue",
        headerName: "Low",
        flex: 1,
        editable: false,
        sortable: true,
        align: "left",
        headerAlign: "left",
    },
    {
        field: "highValue",
        headerName: "High",
        flex: 1,
        editable: false,
        sortable: true,
        align: "left",
        headerAlign: "left",
    },
];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: "100%",
    flexShrink: 1,
    flexGrow: 0,
    boxShadow: "none",
}));

export default function ThresholdDialogV2(props) {
    const { title, onClose, thingId, attributeId, ...other } = props;

    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [rowCount, setRowCount] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);

    const [isOpenInputDialog, setIsOpenInputDialog] = useState(false);
    const { t, i18n } = useTranslation();

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose();
    };

    const handleAdd = () => {
        setIsOpenInputDialog(true);
    };

    const handleRemove = () => {
        ThresholdServiceV2.deleteThreshold(selectedIds, callbackRemove);
    };

    const callbackRemove = () => {
        ThresholdServiceV2.requestThreshold(thingId, attributeId, setRows, setRowCount);
    };

    const closeInputDialog = (refresh) => {
        setIsOpenInputDialog(false);

        if (refresh) {
            ThresholdServiceV2.requestThreshold(thingId, attributeId, setRows, setRowCount);
        }
    };

    const handleSelectionModel = (selectedIds) => {
        setSelectedIds(selectedIds);
    };

    const handleSortModelChange = (param) => {
        let sortModel = param;

        let qParams = [];
        _.each(sortModel, function (sm) {
            let result = "";

            switch (sm.field) {
                case "startTime":
                    result += "start_time";
                    break;
                case "endTime":
                    result += "end_time";
                    break;
                case "isAllTime":
                    result += "is_all_time";
                    break;
                case "lowValue":
                    result += "low_value";
                    break;
                case "highValue":
                    result += "high_value";
                    break;
                default:
                    break;
            }

            switch (sm.sort) {
                case "asc":
                    result += "&dir=ASC";
                    break;
                case "desc":
                    result += "&dir=DESC";
                    break;
                default:
                    break;
            }

            qParams.push(result);
        });

        let sortQuery = "";
        if (qParams.length > 0) {
            sortQuery = "&sort=" + qParams.join(",");
        }

        ThresholdServiceV2.requestThreshold(thingId, attributeId, setRows, setRowCount, sortQuery);
    };

    useEffect(() => {
        ThresholdServiceV2.requestThreshold(thingId, attributeId, setRows, setRowCount);
    }, []);

    return (
        <>
            <Dialog maxWidth="lg" {...other} disableEnforceFocus={true}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div style={{ height: 500, width: 930 }}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={8}>
                                <Item>
                                    <Typography variant="subtitle1" component="subtitle1">
                                        {props.attributeName}
                                    </Typography>
                                </Item>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Item>
                                    <Stack spacing={2} direction="row-reverse">
                                        <Button variant="contained" style={{ color: "#fff", backgroundColor: "#E44959" }} onClick={() => handleRemove()}>
                                            Remove
                                        </Button>
                                        <Button variant="contained" style={{ color: "#fff", backgroundColor: "#9CC96B" }} onClick={() => handleAdd()}>
                                            Add
                                        </Button>
                                    </Stack>
                                </Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Item style={{ width: "100%", height: "400px" }}>
                                    <DataGridPro
                                        rows={rows}
                                        columns={columns}
                                        pagination={true}
                                        rowCount={rowCount}
                                        paginationMode="server"
                                        sortingMode="server"
                                        paginationModel={paginationModel}
                                        onPaginationModelChange={setPaginationModel}
                                        pageSizeOptions={[10, 50, 100]}
                                        onSortModelChange={handleSortModelChange}
                                        checkboxSelection={true}
                                        onRowSelectionModelChange={(newSelection) => handleSelectionModel(newSelection)}
                                        density="compact"
                                        filterMode="server"
                                        // onCellEditCommit={handleName}
                                        disableMultipleColumnsFiltering={true}
                                        disableColumnReorder={true}
                                        sx={{
                                            "& .MuiTablePagination-selectLabel": {
                                                marginTop: 2,
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                marginTop: 2,
                                            },
                                        }}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    id: false,
                                                },
                                            },
                                        }}
                                        localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <ThresholdDialogV2Input open={isOpenInputDialog} close={closeInputDialog} thingId={thingId} attributeId={attributeId}></ThresholdDialogV2Input>
        </>
    );
}
