import ApiService from "Services/apiService";
import moment from "moment";

const MaintService = {
    uploadDoc: async (maintId, fileName, location, bucket, docKey) => {
        let options = {
            url: `/v2/maint/doc`,
            data: {
                maintId,
                fileName,
                location,
                bucket,
                docKey,
            },
        };
        // console.log(options);

        return ApiService.postData(options)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                // console.error(e);
            });
    },

    deleteMaint: async (maintId) => {
        // console.log("mainmaintIdtDocId:" + maintId);

        let option = {
            url: `/v2/maint/${maintId}`,
        };

        return ApiService.deleteData(option)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                // console.error(e);
            });
    },

    deleteMaintDoc: async (maintDocId) => {
        // console.log("maintDocId:" + maintDocId);

        let option = {
            url: `/v2/maint/${maintDocId}/doc`,
        };

        return ApiService.deleteData(option)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                // console.error(e);
            });
    },

    requstMaint: async (thingId, companyId, page, pageSize, sort, setRows, setRowCount) => {
        // console.log(sort);

        let options = {
            url: `/v2/maint`,
            params: {
                page,
                pageSize,
                sort: sort.map((s) => s.field + ":" + s.sort).join(","),
                companyId,
                thingId
            },
        };
        // console.log(options);

        const result = await ApiService.getData(options)
            .then((r) => {
                // console.log(r);
                return r;
            })
            .catch((e) => {
                console.error(e);
            });

        const mapped = result.content.map((c) => ({
            id: c.id,
            description: c.description,
            creationDate: c.creationDate ? new Date(c.creationDate * 1000) : undefined,
            maintDoc: c.maintDoc,
        }));

        setRows(mapped);
        setRowCount(result.totalElements);
    },

    updateMaint: async (maintId, creationDate, description,companyId, thingId) => {
        let options = {
            url: `/v2/maint`,
            data: {
                id: maintId,
                companyId,
                thingId
            },
        };

        if (creationDate) {
            options.data["creationDate"] = moment(creationDate).unix();
        }
        if (description) {
            options.data["description"] = description;
        }

        // console.log(options);

        return ApiService.putData(options)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                // console.error(e);
            });
    },
};

export default MaintService;
