import _ from "underscore";
import ApiService from "Services/apiService";
import UtilService from "Services/utilService";

const LogService = {
    requestLogs: async (page, pageSize, setRowCount, sort, setRows, companyId, thingIds, i18n) => {
        setRows([]); // reset the rows

        let strSort = "";
        if (sort) {
            strSort = _.map(sort, (o) => {
                return (o.sort == "asc" ? "+" : "-") + o.field;
            }).join(",");
        }

        let filter = `company.id:eq_${companyId}`;
        const options = {
            url: `/api/logs`,
            params: {
                page,
                pageSize,
                sort: strSort,
                filter,
            },
        };

        if (thingIds && thingIds.length > 0 && thingIds[0] !== undefined) {
            options.params.filter += `,thing.id:in_${thingIds.join(",")}`;
        }

        // console.log("thingIds", thingIds)
        // console.log(options);

        return ApiService.getData(options)
            .then((response) => {
                // console.log(response);

                let data = [];

                response.data.forEach((ele) => {
                    let message = ele.message;

                    // Handle French translations
                    if (i18n.language === "fr") {
                        // Thing status messages
                        message = message.replace(/Thing status is update to (.+)/, "Le statut de l'objet est mis à jour sur $1");
                        // Import success messages
                        message = message.replace(/Import success: (.+)/, "Importation réussie : $1");
                        // Import start messages
                        message = message.replace(/Import start: (.+)/, "Début de l'importation : $1");
                        // SMS frequency messages
                        message = message.replace(
                            /New frequency SMS to (.+) : Transmission : (.+) min, Measures: (.+) min\. MsgId: (.+)/,
                            "Nouvelle fréquence SMS vers $1 : Transmission : $2 min, Mesures : $3 min. MsgId: $4"
                        );
                    }

                    let obj = {
                        id: ele.id,
                        type: ele.type,
                        message: message,
                        entryDate: i18n.language === "fr" ? UtilService.formatDMYYHMS(ele.entryDate * 1000) : UtilService.formatMDYYHMS(ele.entryDate * 1000),
                        username: ele.username,
                        "thing.fixedname": ele.thing.fixedName,
                    };

                    data.push(obj);
                });

                setRowCount(response.totalSize);
                setRows(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default LogService;
