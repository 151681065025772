import React, { useState, useEffect } from "react";
import { Stage, Layer, Image } from "react-konva";
import Box from "@mui/material/Box";
import IslandKonvaImage from "./IslandKonvaImage";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import ImageService from "Services/imageService";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ImportTool from "../../deviceExplorer/toolBar/ImportTool";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CreateIcon from "@mui/icons-material/Create";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CustomStyledButton from "Components/ui/CustomStyledButton";
import Tooltip from "@mui/material/Tooltip";
import VisiblityMain from "./setting/VisiblityMain";
// import AddStationPopup from "./add/AddStationPopup";

export default function IslandMap({ positions, callback, setReloadParent }) {
    const { t, i18n } = useTranslation();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [reload, setReload] = useState();

    const code = "SUPERVIOT_BG_IMAGE";
    const [backgroundImage, setBackgroundImage] = useState();
    const [openImport, setOpenImport] = useState(false);
    const [openCreateStation, setOpenCreateStation] = useState(false);
    const [scale, setScale] = useState(1);
    const [openVisibleSetting, setOpenVisibleSetting] = useState(false);

    useEffect(() => {
        // console.log("useEffect in IslandMap", positions);

        if (!backgroundImage) {
            ImageService.requestBackgroundImage(code).then((r) => {
                // console.log(r);
                if (r) {
                    const bgImg = new window.Image();
                    bgImg.src = "data:image/png;base64," + r;
                    setBackgroundImage(bgImg);

                    bgImg.onload = () => {
                        setWidth(bgImg.naturalWidth);
                        setHeight(bgImg.naturalHeight);
                    };
                }
            });
        }
    }, [positions, backgroundImage, code]);

    const handleImage = (e) => {
        const auth = process.env.REACT_APP_AUTH_TYPE;

        ImageService.uploadBackgroundImage(code, e.target.files[0], auth).then((r) => {
            // console.log("response", r);
            setBackgroundImage(null);
            setReload(moment());
        });
    };

    const handleCallback = (p) => {
        callback(p);
    };

    const zoomIn = () => {
        setScale((prevScale) => Math.min(prevScale * 1.2, 5));
    };

    const zoomOut = () => {
        setScale((prevScale) => Math.max(prevScale / 1.2, 0.2));
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 0, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2} sx={{ alignItems: "center", wrap: "nowrap" }}>
                <Grid item xs={12} sx={{ gap: 1, display: "flex", flexDirection: "row" }}>
                    <CustomStyledButton icon={<ZoomInIcon />} callback={zoomIn} />
                    <CustomStyledButton icon={<ZoomOutIcon />} callback={zoomOut} />

                    <Tooltip title="Image">
                        <label htmlFor="contained-button-file">
                            <input style={{ display: "none" }} accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />
                            <CustomStyledButton component="span" icon={<AddPhotoAlternateIcon />} />
                        </label>
                    </Tooltip>

                    <Tooltip title={t("superviot.add")}>
                        <label>
                            <CustomStyledButton icon={<CreateIcon />} callback={() => setOpenVisibleSetting(true)} />
                        </label>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100%" }}>
                    <Stage width={width} height={height} scaleX={scale} scaleY={scale} draggable>
                        <Layer>{backgroundImage && <Image image={backgroundImage} />}</Layer>
                        <Layer>
                            {positions &&
                                positions.map((p) => {
                                    return <IslandKonvaImage positions={p} key={uuidv4()} callback={(p) => handleCallback(p)} />;
                                })}
                        </Layer>
                    </Stage>
                </Grid>
            </Grid>
            <ImportTool openImport={openImport} setOpenImport={setOpenImport} />

            <VisiblityMain open={openVisibleSetting} onClose={() => setOpenVisibleSetting(false)} setReloadParent={setReloadParent} />
            {/* <AddStationPopup open={openCreateStation} onClose={() => setOpenCreateStation(false)} /> */}
        </Box>
    );
}
