import { Box } from "@mui/material";

export function AttributeColumns(t) {

    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "name",
            headerName: t("col.name"),
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Box 
                        sx={{ 
                            fontWeight: "bold", 
                            border: "1px solid red", 
                            height: "100%",
                            width: "100%", 
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        {params.value || params.row.name}
                    </Box>
                );
            },
        },
        {
            field: "measurement",
            headerName: "Measurements",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
    ];
}
