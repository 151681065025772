import React, { useEffect, useState, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "../../providers/SettingContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import RoomContainer from "./RoomContainer";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import SiteBox from "./SiteBox";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import SiteDialogue from "Components/site/SiteDialogue";
import dayjs from "dayjs";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
highchartsMore(Highcharts);

export default function Dashboard() {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    const { t, i18n } = useTranslation();

    const [selectedSiteId, setSelectedSiteId] = useState(0);
    const [moyenne, setMoyenne] = useState(false);
    const [alignment, setAlignment] = useState("calendar");
    const [openSite, setOpenSite] = useState(false);
    const [reload, setReload] = useState();
    const [viewType, setViewType] = useState("last");
    const history = useHistory();

    useEffect(() => {
        setting.setGlobalSetting({ appId: AppId.EMERA_V2 });
        ctx.updateTitle(ApplicationData.find((a) => a.id === AppId.EMERA_V2).title);
    }, []);

    const handleAlignment = (event, newAlignment) => {
        // console.log(newAlignment);
        setAlignment(newAlignment);

        if (newAlignment) {
            if (newAlignment == "calendar") {
                setMoyenne(false);
            }
            if (newAlignment == "clock") {
                setMoyenne(true);
            }
        }
    };

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} style={{ flexGrow: "1", display: "flex", flexDirection: "row" }}>
                    <SiteBox selectedSiteId={selectedSiteId} callback={setSelectedSiteId} />
                    <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                        <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <Select
                            value={viewType}
                            onChange={(e) => {
                                setViewType(e.target.value);
                                setMoyenne(e.target.value === "average");
                            }}
                            size="small"
                        >
                            <MenuItem value="last">{t("emera_v2.last_value")}</MenuItem>
                            <MenuItem value="average">{t("emera_v2.daily_average")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <RoomContainer selectedSiteId={selectedSiteId} moyenne={moyenne} setMoyenne={setMoyenne} parentReload={reload} />
                </Grid>
            </Grid>

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                // selectedThingId={selectedThingId}
                setReloadParent={(siteId) => {
                    // console.log(siteId);
                    // setSelectedSiteId(siteId);
                    setReload(dayjs());
                }}
            />
        </Box>
    );
}
