import ApiService from "Services/apiService";
import _ from "underscore";
import dayjs from "dayjs";

const ChartContactorService = {
    requestMeasures: async (thingId, attributeId) => {
        const end = dayjs().unix(); // Current time in milliseconds
        const start = dayjs().subtract(12, "months").unix(); // 24 hours before now in milliseconds

        let requestOptions = {
            url: `/api/measures`,
            params: {
                filter: `thing.id:eq_${thingId},attribute.id:eq_${attributeId},measure.timestamp:bet_${start}-${end}`,
                detailed: true,
                sort: "timestamp",
                dir: "DESC",
                pageSize: 200,
            },
        };

        console.log(requestOptions);
        return ApiService.getData(requestOptions)
            .then((response) => {
                console.log(response);

                let chartData = [];

                response.data.forEach((ele) => {
                    // console.log(ele);

                    let value = ele.value === "open" ? 1 : 0;
                    chartData.push([ele.timestamp * 1000, value, ele.attribute.unit]);
                });

                console.log(chartData);

                return chartData;
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default ChartContactorService;
