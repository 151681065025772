import _ from "underscore";
import ApiService from "Services/apiService";
import UserService from "Services/userService";
import fileDOwnload from "js-file-download";

const ThingService = {
    requestData: async (createData, page, pageSize, setRows, setRowCount, keyword, sortQuery, appId) => {
        let orFilter = "";

        if (keyword) {
            let idsQuery = [];
            idsQuery.push(`{"property":["thing.name", "thing.fixedname", "site.name", "thing.tags"],"operator":"ilike","value":"${keyword}"}`);

            // if (appId == AppId.SMART_AGRICULTURE) {
            //     idsQuery.push(`{"property":["company"],"operator":"eq","value":"CENGN"}`);
            // }

            let concated = idsQuery.join(",");
            orFilter += encodeURI(`&orFilter=[${concated}]`);
        }

        let filter = "";

        const user = await UserService.getAuthenticatedUser();

        // 69714577-5c18-4931-866a-1026b55c603d //atim
        // 8c15a572-e982-485f-8d91-d0b652660e69 : tundra

        if (user && user.company != "PTH") {
            filter = `&filter=company.name:eq_${user.company},product.id:eq_69714577-5c18-4931-866a-1026b55c603d`;
        } else {
            filter = `&filter=product.id:eq_69714577-5c18-4931-866a-1026b55c603d`;
        }

        sortQuery = sortQuery === "" ? "&sort=displayName&dir=ASC" : sortQuery;

        let requestOptions = {
            url: "/api/things?detailed=true&page=" + (page + 1) + "&pageSize=" + pageSize + filter + orFilter + sortQuery,
        };

        // console.log(requestOptions);

        ApiService.getData(requestOptions)
            .then((response) => {
                let totalSize = response.totalSize;

                let values = response.data.map((ele) => {
                    let modelName = "";
                    let manufacturerName = "";
                    let productName = "";
                    let connectivityType = "";
                    let tags = "";
                    let site = "";
                    let fridge = "";

                    if (ele.product) {
                        modelName = ele.customModel ? ele.customModel.name : "";
                        manufacturerName = ele.product.manufacturer.name;
                        productName = ele.product.name;
                        connectivityType = ele.product.connectivityTypes[0];

                        let arrTags = [];
                        _.each(ele.product.tags, function (ele) {
                            arrTags.push(ele.tag);
                        });
                        tags = arrTags.join(", ");
                    }

                    if (ele.site) {
                        site = ele.site.name;
                    }

                    if (ele.clusterNames) {
                        fridge = JSON.parse(ele.clusterNames).join(", ");
                    }

                    return createData(
                        ele.id,
                        ele.company,
                        ele.displayName,
                        ele.lastMessageDate,
                        modelName,
                        manufacturerName,
                        productName,
                        connectivityType,
                        ele.status,
                        tags,
                        site,
                        fridge
                    );
                });

                setRowCount(totalSize);
                setRows(values);
            })
            .catch((e) => console.log(e));
    },

    requestModel: async (thingId, setModel) => {
        let requestOptions = {
            url: `/api/things/${thingId}/model`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestModel...Response");
                // console.log(response);

                let attributes = [];
                attributes.push({ id: "all", label: "All" });
                response.attributes.forEach((attr) => {
                    attributes.push({
                        id: attr.id,
                        label: attr.name,
                    });
                });
                setModel(attributes);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestReport: async (createData, page, pageSize, setRows, allColumns, setColumns, keyword, sortQuery, on, reportType) => {
        let orFilter = "";

        if (keyword != undefined) {
            let idsQuery = [];
            idsQuery.push(`{"property":["thing.name", "thing.fixedname", "site.name", "thing.tags"],"operator":"ilike","value":"${keyword}"}`);

            // if (appId == AppId.SMART_AGRICULTURE) {
            //     idsQuery.push(`{"property":["company"],"operator":"eq","value":"CENGN"}`);
            // }

            let concated = idsQuery.join(",");
            orFilter += encodeURI(`&orFilter=[${concated}]`);
        }

        let filter = "";

        const user = await UserService.getAuthenticatedUser();
        if (user && user.company != "PTH") {
            filter = `&filter=company.name:eq_${user.company}`;
        }

        sortQuery = sortQuery === "" ? "&sort=displayName&dir=ASC" : sortQuery;

        let requestOptions = {
            url: "/api/report?detailed=true&page=" + (page + 1) + "&pageSize=" + pageSize + filter + orFilter + sortQuery + "&reportType=" + reportType,
        };

        // console.log(requestOptions);

        ApiService.downloadFile(requestOptions)
            .then((response) => {
                fileDOwnload(response, `SensorList.${reportType}`);
            })
            .catch((e) => console.log(e));
    },

    validateThingInCluster: async (clusterId, thingIds) => {
        let requestOptions = {
            url: `/api/cluster/${clusterId}/validate`,
            data: { thingId: thingIds },
        };

        // console.log("requestOptions...in validateThingInCluster");
        // console.log(requestOptions);

        return ApiService.putData(requestOptions)
            .then((response) => {
                const body = response.body;
                // console.log(body)

                if (body.code == "in use") {
                    return true;
                } else {
                    return false;
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    addThingInCluster: async (clusterId, thingIds) => {
        let requestOptions = {
            url: `/api/cluster/${clusterId}`,
            data: { thingId: thingIds },
        };

        return ApiService.putData(requestOptions)
            .then(() => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    removeThingInCloud: async (clusterId, thingIds) => {
        let requestOptions = {
            url: `/api/cluster/${clusterId}`,
            data: { thingId: thingIds },
        };

        return ApiService.deleteData(requestOptions)
            .then(() => {
                // console.dir(response);
            })
            .catch((e) => console.error(e));
    },
};

export default ThingService;
