import React, { useState, Suspense, useEffect } from "react";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import style from "./style.module.css";
import { Auth } from "aws-amplify";
import { useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import validator from "validator";
import basicAuthService from "Services/basicAuthService";
import Typography from "@mui/material/Typography";

const authType = process.env.REACT_APP_AUTH_TYPE;

function ForgotPassword() {
    let param = useParams();
    let history = useHistory();

    const { t, i18n } = useTranslation();

    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [info, setInfo] = useState();

    useEffect(() => {
        // console.log("uef in ForgotPassword")
        // console.log(param)
    }, []);

    const submit = (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        if (authType === "basic") {
            basicAuthService.login(param.email, password).then((e) => {
                setLoading(false);
                history.push("/apps");
            });
        } else {
            Auth.signIn(param.email, password)
                .then((e) => {
                    // sucesss
                    setLoading(false);
                    history.push("/apps");
                })
                .catch((e) => {
                    setLoading(false);
                    setError("Incorrect username or password");
                    console.error(e);
                });
        }
    };

    const handleReset = () => {
        if (validator.isEmpty(password)) {
            setError("login.password-cannottbe-empty");
            return;
        } else if (validator.isEmpty(code)) {
            setError(t("login.code-cannottbe-empty"));
            return;
        } else if (password != confirmPassword) {
            setError(t("login.passward-not-same"));
            return;
        }

        setError("");

        if (authType === "basic") {
            basicAuthService
                .resetPassword(decodeURIComponent(param.email), code, password)
                .then((u) => {
                    setLoading(false);
                    history.push("/apps");
                })
                .catch((e) => {
                    console.error(e);
                });
        } else {
            Auth.forgotPasswordSubmit(decodeURIComponent(param.email), code, password, {})
                .then((u) => {
                    setLoading(false);
                    history.push("/apps");
                })
                .catch((e) => {
                    console.error(e);
                    switch (e.name) {
                        case "UserNotFoundException":
                            setError(t("login.user-notfound"));
                            break;
                        case "LimitExceededException":
                            setError(t("login.limit-exceeded"));
                            break;
                        default:
                            setError(t("login.retry-later"));
                            break;
                    }
                });
        }
    };

    const handleResend = () => {
        setError("");
        if (authType === "basic") {
            basicAuthService.forgotPassword(decodeURIComponent(param.email)).then(() => {
                setInfo(t("login.sent-again"));
            });
        } else {
            Auth.forgotPassword(decodeURIComponent(param.email), {})
                .then(() => {
                    setInfo(t("login.sent-again"));
                })
                .catch((e) => console.log(e));
        }
    };

    return (
        <>
            <div className={style.background}>
                <Box sx={{ backgroundColor: "white" }}>
                    <Suspense fallback={<span />}>
                        <Header />
                    </Suspense>
                    <Box sx={{ p: 10 }}>
                        {process.env.REACT_APP_AUTH_TYPE === "basic" ? (
                            <>
                                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: '100%'   }}>
                                    <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2,  textAlign: 'center' }}>
                                        {t("superviot.app_name")}
                                    </Typography>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <img src="/apps/logo_tahiti.jpg" alt="Pilot Things" style={{ marginBottom: 20, height: 100 }} />
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <img src="/apps/full-logo.png" alt="Pilot Things" style={{ marginBottom: 20 }} />
                        )}

                        <h4>{t("login.use-code")}</h4>
                        <Stack component="form" spacing={2} onSubmit={submit}>
                            <TextField
                                variant="standard"
                                label={t("login.code")}
                                type="password"
                                fullWidth
                                required
                                disabled={loading}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            <TextField
                                variant="standard"
                                label={t("login.password")}
                                type="password"
                                fullWidth
                                required
                                value={password}
                                disabled={loading}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <TextField
                                variant="standard"
                                label={t("login.enter-password-again")}
                                type="password"
                                fullWidth
                                required
                                value={confirmPassword}
                                disabled={loading}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />

                            {/* opacity is a clever trick to hide the error message while still reserving space for it. */}
                            <Alert severity="error" sx={{ opacity: error ? 1 : 0 }}>
                                {error}
                            </Alert>
                            {/* <LoadingButton loading={loading} loadingPosition="end" variant="contained" type="submit" endIcon={<LockOpenIcon />} fullWidth>{t('login.login')}</LoadingButton> */}
                            <Alert severity="info" sx={{ opacity: info ? 1 : 0 }}>
                                {info}
                            </Alert>

                            <Button variant="contained" onClick={handleReset}>
                                {t("login.reinitializer")}
                            </Button>
                            <Box sx={{ mt: 3 }} textAlign="center">
                                <Link onClick={handleResend} sx={{ cursor: "pointer" }}>
                                    {t("login.resend-code")}
                                </Link>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </div>
        </>
    );
}

export default ForgotPassword;
