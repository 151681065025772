import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { BootstrapDialog, BootstrapDialogTitle } from "Components/ui/BootstrapDialog";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CompanyService from "./CompanyService";
import Alert from "@mui/material/Alert";

export default function CompanyDialog({ open, setOpen }) {
    const { t } = useTranslation();
    const [days, setDays] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [daysError, setDaysError] = useState(false);
    const [error, setError] = useState("");

    const handleClose = () => {
        setDays(0);
        setCompanyName("");
        setNameError(false);
        setDaysError(false);
        setError("");
        setOpen(false);
    };

    const handleAdd = () => {
        setError("");
        const cleanedName = companyName
            .replace(/[^a-zA-Z\s]/g, "")
            .trim()
            .replace(/\s+/g, "_");
        const daysNum = Number(days);

        if (!cleanedName) {
            setNameError(true);
            setError(t("biz.name_error"));
            return;
        }

        if (isNaN(daysNum) || daysNum < 0) {
            setDaysError(true);
            setError(t("biz.error"));
            return;
        }

        CompanyService.addCompany(cleanedName, daysNum)
            .then((r) => {
                if (r.code && r.code === "failure") {
                    setNameError(true);
                    setError(t("biz.error"));
                } else {
                    handleClose();
                }
            })
            .catch((error) => {
                console.error(error);
                setDaysError(true);
                setError(t("biz.error"));
            });
    };

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
            <BootstrapDialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {t("biz.add_company")}
            </BootstrapDialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <TextField
                    variant="standard"
                    autoFocus
                    margin="dense"
                    id="company-name"
                    label={t("biz.name")}
                    type="text"
                    fullWidth
                    value={companyName}
                    onChange={(e) => {
                        setCompanyName(e.target.value);
                        setNameError(false);
                    }}
                    error={nameError}
                    helperText={nameError ? t("biz.name_error") : ""}
                />
                <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: 1, alignItems: "center", justifyContent: "center" }}>
                    <Typography gutterBottom>{t("biz.limit_retention")}</Typography>
                    <TextField
                        margin="dense"
                        id="days"
                        label=""
                        type="number"
                        value={days}
                        onChange={(e) => {
                            setDays(e.target.value);
                            setDaysError(false);
                        }}
                        inputProps={{ min: 0 }}
                        sx={{ width: 100 }}
                        helperText={daysError ? t("biz.error") : ""}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleAdd}>
                    {t("btn.add")}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
