import ApiService from "Services/apiService";
import UtilService from "Services/utilService";
import { AppId } from "Components/applications/ApplicationData";

const EmailSettingService = {
    findNotification: async (companyId, setEmail) => {
        let requestOptions = {
            url: `/api/notification/${companyId}/company`,
        };

        return ApiService.getData(requestOptions)
            .then((r) => {
                // console.log(r);
                if (r) {
                    const uniqueEmails = [...new Set(r)];
                    setEmail(uniqueEmails.join(";"));
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateNotification: async (companyId, emails) => {
        let requestOptions = {
            url: `/api/notification/subscribe_email`,
            data: {
                applicationId: AppId.SUPERVIOT,
                companyId: companyId,
                locale: "fr",
                emails: emails,
            },
        };
        // console.log(requestOptions);

        return (
            ApiService.putData(requestOptions)
                .then((response) => {
                    // console.log(response);
                    return response;
                })
                .catch((e) => {
                    console.error(e);
                })
        );
    },

    requestLastNotifications: async (setLastSms, setLastEmail, appId, locale) => {
        let requestOptions = {
            url: `/api/notification/last/${appId}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);

                response.forEach((ele) => {
                    if (ele.notificationType == "PHONE") {
                        if (setLastSms) {
                            setLastSms(UtilService.formatMDYHm(ele.creationTimeStamp, locale));
                        }
                    } else if (ele.notificationType == "EMAIL") {
                        if (setLastEmail) {
                            setLastEmail(UtilService.formatMDYHm(ele.creationTimeStamp, locale));
                        }
                    }
                });
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default EmailSettingService;
