import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import style from "./style.module.css";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import ShareIcon from "@mui/icons-material/Share";
import CardService from "./CardService";
import Typography from "@mui/material/Typography";
import UtilService from "Services/utilService";
import RoomContactorSetting from "./RoomContactorSetting";

export default function RoomContactorCard({ asset, moyenne }) {
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(0);

    const [magnetStatus, setMagnetStatus] = useState({});
    const [battery, setBattery] = useState(null);
    const [tamperStatus, setTamperStatus] = useState(null);
    const [siteId, setSiteId] = useState(null);
    const [siteName, setSiteName] = useState(null);
    const [productUrl, setProductUrl] = useState(null);
    const [fixedName, setFixedName] = useState(null);
    const [contactSetting, setContactSetting] = useState(null);

    useEffect(() => {
        // console.log("useEffect in RoomMagnetCard");
        // console.log(asset);

        let attributeIds = [];
        attributeIds.push(asset.assetAttribute[0].attribute.id);

        if (asset.assetAttribute.length > 1) {
            attributeIds.push(asset.assetAttribute[1].attribute.id);
        }

        const thingId = asset.assetAttribute[0].thing.id;

        // console.log(attributeId);
        // console.log(thingId);

        CardService.requestLastMeasures(thingId, attributeIds).then((r) => {
            // console.log(r);
            r.forEach((ele) => {
                if (ele.attribute.name === "battery") {
                    setBattery(Number(ele.value));
                    return false;
                }

                if (ele.attribute.name === "magnet_status") {
                    // console.log(ele);

                    setMagnetStatus({
                        thingId: ele.thingId,
                        fixedName: ele.fixedName,
                        value: ele.value,
                        unit: ele.attribute.unit,
                        date: ele.timestamp / 1000,
                        attributeId: ele.attribute.id,
                    });
                }

                if (ele.attribute.name === "tamper_status") {
                    setTamperStatus(ele.value);
                    console.log(ele.value);
                }
            });

            // console.log("mapped", mapped);
            // setEntity(mapped);
            setSiteId(asset.site ? asset.site.id : null);
            setSiteName(asset.site ? asset.site.name : null);
        });

        CardService.requestThing(thingId).then((r) => {
            setFixedName(r.fixedName);

            if (r?.product) {
                let temp = `/static/catalog/products/${r.product.id}/image.png`;
                setProductUrl(temp);
            }

            if (r?.customFields) {
                r.customFields.filter((ele) => {
                    if (ele.name === "EMERA_V2_CONTACT_SETTING") {
                        setContactSetting(JSON.parse(ele.value));
                    }
                });
            }
        });
    }, [asset, moyenne, reload]);

    const handleFloor = () => {
        history.push(`${process.env.PUBLIC_URL}/emera/v2/floor/${siteId}`);
    };

    const getBatteryIcon = (battery) => {
        if (!battery && battery === 100) return "fa-battery-full";

        if (battery <= 0) return "fa-battery-empty";
        if (battery <= 25) return "fa-battery-quarter";
        if (battery <= 50) return "fa-battery-half";
        if (battery <= 75) return "fa-battery-three-quarters";
        return "fa-battery-full";
    };

    const handleMagnetStatus = (thingId, attributeId) => {
        // console.log("handleMagnetStatus", thingId, attributeId);
        history.push(`${process.env.PUBLIC_URL}/emera/v2/chart_column/${thingId}/${attributeId}`);
    };

    return (
        <Box sx={{ margin: 3, width: "300px", minHeight: 270 }}>
            <Paper elevation={8} sx={{ 
                borderRadius: "15px", 
                p: 2, 
                height: "100%", 
                backgroundColor: tamperStatus === 'uninstalled' ? '#FF7F50' : 'inherit'
            }}>
                <Grid container sx={{ height: "100%" }}>
                    {/* Room name */}
                    <Grid item xs={7} sx={{ height: "30px" }}>
                        <span>{fixedName}</span> 
                    </Grid>

                    {/* Battery icon and Calendar icon */}
                    <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end", height: "30px" }}>
                        <IconButton aria-label="detail" size="large" onClick={() => setOpen(true)}>
                            <i className="fa fa-cog" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                        </IconButton>
                        {battery && (
                            <Tooltip title={`${battery}%`} arrow>
                                <IconButton
                                    aria-label="btn-battery"
                                    sx={{
                                        "&.MuiButtonBase-root:hover": {
                                            cursor: "default",
                                        },
                                    }}
                                    size="small"
                                >
                                    <i className={`fa ${getBatteryIcon(battery)}`} aria-hidden="true" style={{ fontSize: "20px" }}></i>
                                </IconButton>
                            </Tooltip>
                        )}

                        {magnetStatus.isMaster && (
                            <IconButton aria-label="btn-cock" size="small" style={{ filter: "grayscale(50%)" }}>
                                <ShareIcon size="small" />
                            </IconButton>
                        )}
                    </Grid>

                    <Grid item xs={6} sx={{ mt: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img
                                src={productUrl}
                                alt="Product"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "contain",
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ mt: 2 }}>
                        <Typography variant="h5" gutterBottom sx={{ textAlign: "center", mb: 0 }}>
                            {magnetStatus && (
                                <IconButton
                                    sx={{ color: magnetStatus.value === "open" ? "#70AD47" : "#FF0000" }}
                                    onClick={() => handleMagnetStatus(magnetStatus.thingId, magnetStatus.attributeId)}
                                >
                                    {magnetStatus.value} {magnetStatus.unit}
                                </IconButton>
                            )}
                        </Typography>
                        <Typography variant="body2" gutterBottom sx={{ textAlign: "center" }}>
                            {magnetStatus && magnetStatus.date && UtilService.formatMDYYHMS(magnetStatus.date * 1000)}
                        </Typography>
                    </Grid>

                    {/* Room name */}
                    <Grid item xs={12} sx={{ display: "flex", flexGrow: 1, alignItems: "flex-end" }}>
                        <span className={style.box} onClick={() => handleFloor()}>
                            {siteName}
                        </span>
                    </Grid>
                </Grid>
            </Paper>
            <RoomContactorSetting open={open} setOpen={setOpen} entity={magnetStatus} contactSetting={contactSetting} />
        </Box>
    );
}
