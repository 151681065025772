import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AppContext from "Components/AppContext";
import { useTranslation } from "react-i18next";
import SettingContext from "Providers/SettingContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import RoomConstants from "../RoomConstants";
import { Box } from "@mui/material";
import KonvaCanvas from "./KonvaCanvas";
import FlowService from "./FlowService";

export default function Floor() {
    const SITE_ID_SIEGE = "abc704f7-e1ee-4db6-b40d-b4a70626b92c";

    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);

    const { t, i18n } = useTranslation();
    let { siteId } = useParams();

    const [data, setData] = useState();

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA_V2,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        ctx.updateTitle(ApplicationData.find((a) => a.id === AppId.EMERA_V2).title);

        if (siteId === SITE_ID_SIEGE) {
            setData(RoomConstants.rowsA);
            // } else if (siteId === "37ded6e6-4ff7-4bf3-93fb-b17280d9db0e") {
            //     setData(RoomConstants.rowsB);
        }

        // console.log(RoomConstants.rowsB);
    }, []);

    useEffect(() => {
        
        if (siteId !== SITE_ID_SIEGE) {
            FlowService.requestThing(siteId).then((response) => {
                // console.log(response);

                let mapped = response.data.map((t) => {
                    let mapped = {
                        id: t.id,
                        name: t.displayName,
                        measurements: {},
                    };

                    // Handle all measurements
                    Object.entries(t.lastMeasurements.measurements).forEach(([key, value]) => {
                        if (key === "averageAmps" || key === "magnet_status") {
                            mapped.measurements[key] = {
                                value: value,
                                unit: t.lastMeasurements.units[key] || null,
                                timestamp: t.lastMeasurementsTimestamps.measurements[key],
                            };
                        }
                    });

                    if (t.customFields) {
                        let position = t.customFields.filter((c) => c.name === "EMERA_V2_ICON_POSITION");
                        if (position.length > 0) {
                            mapped.position = JSON.parse(position[0].value);
                        }
                    }

                    return mapped;
                });
                setData(mapped);
            });
        }
    }, [siteId]);

    return (
        <Box sx={{ padding: "56px" }}>
            <KonvaCanvas siteId={siteId} data={data} />
        </Box>
    );
}
