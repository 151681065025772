import _ from "underscore";
import ApiService from "Services/apiService";
import Coordinator from "./Coordinator";
import { AppId } from "Components/applications/ApplicationData";
import UserService from "Services/userService";
import fileDownload from "js-file-download";

const MeasurementService = {
    requestData: async (createData, page, pageSize, setRows, allColumns, setColumns, keyword, sortQuery, on, appId, setRowCount) => {
        let orFilter = "";

        if (keyword != undefined) {
            let idsQuery = [];
            idsQuery.push(`{"property":["thing.name", "thing.fixedname", "site.name", "thing.tags"],"operator":"ilike","value":"${keyword}"}`);

            if (appId == AppId.SMART_AGRICULTURE) {
                idsQuery.push(`{"property":["company"],"operator":"eq","value":"CENGN"}`);
            }

            let concated = idsQuery.join(",");
            orFilter += encodeURI(`&orFilter=[${concated}]`);
        }

        let filter = "";

        // const user = await UserService.getAuthenticatedUser();
        // if (user && user.authorities != "SUPER_ADMIN" && user.company != 'PTH') {
        //     filter = `&filter=company.name:eq_${user.company}`;
        // }

        sortQuery = sortQuery === "" ? "&sort=displayName&dir=ASC" : sortQuery;

        let requestOptions = {
            url: "/api/things?detailed=true&page=" + (page + 1) + "&pageSize=" + pageSize + filter + orFilter + sortQuery,
        };

        let enableColumns = [];

        ApiService.getData(requestOptions)
            .then((response) => {
                let totalSize = response.totalSize;
                let excludeAttributes = ["_Battery", "_battery", "mode", "event_count", "minutes_since_last_event", "relative_humidity"];

                let values = response.data.map((ele) => {
                    let modelName = "";
                    let manufacturerName = "";
                    let productName = "";
                    let connectivityType = "";
                    let tags = "";
                    let site = "";

                    if (ele.product != undefined) {
                        modelName = ele.customModel != undefined ? ele.customModel.name : "";
                        manufacturerName = ele.product.manufacturer.name;
                        productName = ele.product.name;
                        connectivityType = ele.product.connectivityTypes[0];

                        let arrTags = [];
                        _.each(ele.tags, function (e) {
                            arrTags.push(e.tag);
                        });
                        tags = arrTags.join(", ");
                    }

                    let attributes = [];

                    if (ele.lastMeasurements != undefined) {
                        let measurement = ele.lastMeasurements.measurements;

                        if (measurement != undefined) {
                            _.each(measurement, function (value, key) {
                                // If any attribute doesn't show up, put the attribute into Attributes in Coordinator.js
                                // console.log(key);

                                if (!excludeAttributes.includes(key)) {
                                    var temp = {};
                                    var id = Coordinator.getId(key);

                                    temp[id] = value;
                                    attributes.push(temp);

                                    let foundColumn = false;
                                    _.each(enableColumns, function (col) {
                                        if (col == id) {
                                            foundColumn = true;
                                        }
                                    });

                                    if (!foundColumn) {
                                        enableColumns.push(id);
                                    }
                                }
                            });
                        }
                    }

                    if (ele.site != undefined) {
                        site = ele.site.name;
                    }

                    return createData(
                        ele.id,
                        ele.company,
                        ele.displayName,
                        ele.lastMessageDate,
                        modelName,
                        manufacturerName,
                        productName,
                        connectivityType,
                        ele.status,
                        tags,
                        site,
                        attributes
                    );
                });

                setRows(values);
                setRowCount(totalSize);

                _.each(enableColumns, function (enableCol) {
                    _.each(allColumns, function (col) {
                        if (enableCol == col.field) {
                            col["hide"] = false;
                        }
                    });
                });

                // Turning the measurement field on or off
                if (on) {
                    for (var i = 0; i < allColumns.length; i++) {
                        if (allColumns[i].field.startsWith("id_")) {
                            allColumns[i].hide = false;
                        }
                    }
                } else {
                    for (var i = 0; i < allColumns.length; i++) {
                        if (allColumns[i].field.startsWith("id_")) {
                            allColumns[i].hide = true;
                        }
                    }
                }

                setColumns(allColumns);
            })
            .catch((e) => console.error(e));
    },

    requestModel: async (thingId, setModel) => {
        let requestOptions = {
            url: `/api/things/${thingId}/model`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestModel...Response");
                // console.log(response);

                let attributes = [];
                attributes.push({ id: "all", label: "All" });
                response.attributes.forEach((attr) => {
                    attributes.push({
                        id: attr.id,
                        key: attr.id,
                        label: attr.name,
                    });
                });
                setModel(attributes);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requesCustomtModel: async (thingId, setModel) => {
        let requestOptions = {
            url: `/api/things/${thingId}/custom_model/attributes`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requesCustomtModel...Response");
                // console.log(response);

                if (!response.message) {
                    setModel((model) => {
                        response.forEach((e) =>
                            model.push({
                                id: e.id,
                                key: e.id,
                                label: e.name,
                            })
                        );
                        return model;
                    });
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestReport: async (page, pageSize, keyword, sortQuery, reportType) => {
        let orFilter = "";

        if (keyword != undefined) {
            let idsQuery = [];
            idsQuery.push(`{"property":["thing.name", "thing.fixedname", "site.name", "thing.tags"],"operator":"ilike","value":"${keyword}"}`);

            // if (appId == AppId.SMART_AGRICULTURE) {
            //     idsQuery.push(`{"property":["company"],"operator":"eq","value":"CENGN"}`);
            // }

            let concated = idsQuery.join(",");
            orFilter += encodeURI(`&orFilter=[${concated}]`);
        }

        let filter = "";

        const user = await UserService.getAuthenticatedUser();
        if (user && user.company != "PTH") {
            filter = `&filter=company.name:eq_${user.company}`;
        }

        sortQuery = sortQuery === "" ? "&sort=displayName&dir=ASC" : sortQuery;

        let requestOptions = {
            url: "/api/report?detailed=true&page=" + (page + 1) + "&pageSize=" + pageSize + filter + orFilter + sortQuery + "&reportType=" + reportType,
        };

        // console.log(requestOptions);

        ApiService.downloadFile(requestOptions)
            .then((response) => {
                fileDownload(response, `SensorList.${reportType}`);
            })
            .catch((e) => console.error(e));
    },
};

export default MeasurementService;
